import {image} from "./widgets/image";
import UpdateImage from "./widgets/UpdateImage";
import Storage from "./views/storage/Storage";


const routes = {
    storage: {path: "/cms/storage", component: Storage, middleware: 'cms.AdminAuthenticatedMiddleware'},
};
const cmsLeftMenu = [
    {path: routes.storage.path, name: "Fajlovi", position: 6},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    widgets: {
        image: image,
        UpdateImage: UpdateImage
    }
}
export default exports;