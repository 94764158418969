import React from "react";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import {useQuery} from "@apollo/client";
import {GET_SELF, GET_SELF_QUERY, UPDATE_USER} from "../../graphql/user";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import Layout from "../layout/Layout";


const Dashboard = (props) => {
    const {data, loading, error} = useQuery(GET_SELF, {
        fetchPolicy: "network-only"
    });
    if(loading) return <Layout><Loader /></Layout>
    if(error) return <Layout><GraphQLErrorMessage error={error} /></Layout>
    const user = data[GET_SELF_QUERY];
    if(!user) return <Layout>Korisnik ne postoji!</Layout>

    return (
        <Layout>
            <div className="bg-white p-3 rounded-3">
                <div className="row">
                    <div className="col-md-6">
                        <div className="fw-bold mb-1">Email</div>
                        <div className="mb-3">{user.email}</div>

                        <div className="fw-bold mb-1">Ime</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="name"
                            required={true}
                            placeholder="Ime"
                            className="mb-3"
                            value={user.name} />

                        <div className="fw-bold mb-1">Prezime</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="lastname"
                            required={true}
                            placeholder="Prezime"
                            className="mb-3"
                            value={user.lastname} />

                        <div className="fw-bold mb-1">Telefon</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="phone"
                            required={true}
                            placeholder="Telefon"
                            className="mb-3"
                            value={user.phone} />
                    </div>
                    <div className="col-md-6">
                        <div className="fw-bold mb-1">Ime firme</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="firm_name"
                            nullable={true}
                            placeholder="Ime firme"
                            className="mb-3"
                            value={user.firm_name} />

                        <div className="fw-bold mb-1">Adresa firme</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="firm_address"
                            nullable={true}
                            placeholder="Adresa firme"
                            className="mb-3"
                            value={user.firm_address} />

                        <div className="fw-bold mb-1">Mesto firme</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="firm_place"
                            nullable={true}
                            placeholder="Mesto firme"
                            className="mb-3"
                            value={user.firm_place} />

                        <div className="fw-bold mb-1">PIB firme</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="firm_pib"
                            nullable={true}
                            placeholder="PIB firme"
                            className="mb-3"
                            value={user.firm_pib} />

                        <div className="fw-bold mb-1">Matični broj firme</div>
                        <CmsUpdateField
                            element="text"
                            id={user.id}
                            MUTATION={UPDATE_USER}
                            name="firm_number"
                            nullable={true}
                            placeholder="Matični broj firme"
                            className="mb-3"
                            value={user.firm_number} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard;
