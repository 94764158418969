import React, {useState} from "react";
import Categories from "./Categories";
import Drawer from "rc-drawer";
import {useSwipeable} from "react-swipeable";

function CategoriesDrawer({button}) {
    const [categoriesDrawer, setCategoriesDrawer] = useState(false);
    const swipeLeftToClose = useSwipeable({
        onSwipedLeft: (eventData) => setCategoriesDrawer(false),
    });

    return (
        <>
            <Drawer
                open={categoriesDrawer}
                onClose={() => setCategoriesDrawer(false)}
                handler={null}
                width={"360px"}
                level={null}>
                <div {...swipeLeftToClose} className="mt-3">
                    <Categories close={() => setCategoriesDrawer(false)} />
                </div>
            </Drawer>

            {{...button, props: {...button.props, onClick: () => setCategoriesDrawer(!categoriesDrawer)}}}
        </>

    )
}
export default CategoriesDrawer;