import {wrappers} from "./modules/modulesCompiler";
import {Toaster} from "react-hot-toast";

function App() {
    const wrap = (modules, index = 0) => {
        if(!modules[index])       {
            return null;
        }
        const Wrapper = modules[index];
        return <Wrapper>
            {wrap(modules,index+1)}
        </Wrapper>
    }
    return (
        <div>
            <Toaster />
            {wrap(wrappers)}
        </div>
    );
}

export default App;
