import React from "react";
import {useQuery} from "@apollo/client";
import {GET_USERS_ANALYTICS, GET_USERS_ANALYTICS_QUERY} from "../../graphql/analytics";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import Chart from "react-google-charts";
import moment from "moment";


const GeoStatistics = ({days}) => {
    const dateFormat = "MM/DD/YYYY";
    const { data, loading, error } =  useQuery(GET_USERS_ANALYTICS, {
        variables: {days: days}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return <div className="alert alert-danger"> Nema podataka. </div>
    const analytics = data[GET_USERS_ANALYTICS_QUERY];

    const usersHolder = {};
    const usersCount = {activeUsers: 0, newUsers: 0};
    const sessionsHolder = {};
    const sessionsCount = {screenPageViews: 0, sessions: 0, sessionsPerUser: 0};
    for(let i=0; i<analytics.length; i++)   {
        const dateStr = moment(analytics[i].date).format(dateFormat);
        usersHolder[dateStr] = [analytics[i].activeUsers, analytics[i].newUsers];
        usersCount.activeUsers += analytics[i].activeUsers;
        usersCount.newUsers += analytics[i].newUsers;
        sessionsHolder[dateStr] = [analytics[i].screenPageViews, analytics[i].sessions, analytics[i].sessionsPerUser];
        sessionsCount.screenPageViews += analytics[i].screenPageViews;
        sessionsCount.sessions += analytics[i].sessions;
        sessionsCount.sessionsPerUser += analytics[i].sessionsPerUser;
    }


    const getDaysBetweenDates = function (startDate, endDate, result, def) {
        const now = startDate.clone(), dates = [];

        while (now.isSameOrBefore(endDate)) {
            const dateStr = now.format(dateFormat);
            if(result[dateStr]) {
                dates.push([dateStr, ...result[dateStr]]);
            } else  {
                dates.push([dateStr, ...def]);
            }
            now.add(1, 'days');
        }
        return dates;
    };
    var dateList = getDaysBetweenDates(moment().subtract(days, "day"), moment(), usersHolder, [0, 0]);
    var sessionsList = getDaysBetweenDates(moment().subtract(days, "day"), moment(), sessionsHolder, [0, 0, 0]);

    return (
        <div>
            <div className="bg-white rounded-3 p-3 mb-4">
                <h3>Broj korisnika</h3>
                <div>Aktivni (The number of distinct users who visited your site or app): {usersCount.activeUsers}</div>
                <div className="mb-3">Novi (The number of users who interacted with your site or launched your app for the first time (event triggered: first_open or first_visit)): {usersCount.newUsers}</div>
                <Chart
                    width="100%"
                    height={300}
                    chartType="ComboChart"
                    loader={<div>Loading...</div>}
                    data={[
                        ["Datum", "Activni korisnici", "Novi korisnici"],
                        ...dateList
                    ]}
                    options={{
                        seriesType: 'bars',
                        series: { 5: { type: 'line' } },
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>

            <div className="bg-white rounded-3 p-3">
                <h3>Broj sesija</h3>
                <div>Broj stranica - The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events): {sessionsCount.screenPageViews}</div>
                <div>Sesije - The number of sessions that began on your site or app (event triggered: session_start): {sessionsCount.sessions}</div>
                <div className="mb-3">Sesije po korisniku - The average number of sessions per user (Sessions divided by Active Users): {sessionsCount.sessionsPerUser}</div>
                <Chart
                    width="100%"
                    height={300}
                    chartType="ComboChart"
                    loader={<div>Loading...</div>}
                    data={[
                        ["Datum", "Broj stranica", "Sesija", "Sesija po korisniku"],
                        ...sessionsList
                    ]}
                    options={{
                        seriesType: 'bars',
                        series: { 5: { type: 'line' } },
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        </div>
    )
}

export default GeoStatistics;
