import {useQuery} from "@apollo/client";
import {findById} from "../helpers";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../graphql/categories";


function useGetBreadcrumbsBySlug(categorySlug) {
    const { data, loading } =  useQuery(GET_CATEGORIES, {
        //variables: {parent: null} TODO rmv
    });
    if(loading) return {breadcrumbs: [], categories:[], loading};
    if(!data) return {breadcrumbs: [], categories:[], loading};
    const categories = data[GET_CATEGORIES_QUERY];
    //let breadcrumbs = findBySlug(categories, categorySlug); TODO rmv
    let breadcrumbs = findById(categories, categorySlug);
    return {breadcrumbs, categories, loading};
}
export default useGetBreadcrumbsBySlug;