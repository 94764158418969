import BasketDrawerContext from "../contexts/BasketDrawerContext";
import {useState} from "react";


function GlobalBasketDrawerWrapper({children})    {
    const [open, setOpen] = useState(false);

    return <BasketDrawerContext.Provider value={{open, setOpen}}>
        {children}
    </BasketDrawerContext.Provider>
}
export default GlobalBasketDrawerWrapper;