import React from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_ORDER_BY_CODE, GET_ORDER_BY_CODE_QUERY} from "../../../orders/graphql/order";
import {widget} from "../../../modulesCompiler";
import Layout from "../layout/Layout";



const Order = (props) => {
    const code = props.computedMatch?.params?.code;
    const {data, loading, error} = useQuery(GET_ORDER_BY_CODE, {
            variables: {code: code},
        });
    if(loading) {
        return <Layout><Loader /></Layout>
    }
    if(error)   {
        return <Layout><GraphQLErrorMessage error={error} /></Layout>
    }
    const order = data[GET_ORDER_BY_CODE_QUERY];
    if(!order)  {
        return <Layout>{widget("public.Error404")}</Layout>;
    }

    return (
        <Layout>
            {widget("orders.OrderRender", {order})}
        </Layout>
    )
}

export default Order;
