import {useContext} from "react";
import {routes} from "../../modulesCompiler";
import LangContext from "../contexts/LangContext";


function useRoute({path, params})  {
    const {lang} = useContext(LangContext);

    if(!routes[path])       {
        console.log("MISSING: ", `Ne postoji route: ${path}`)
        return "";
    }
    const compile = routes[path].compile;
    if(compile)  {
        return compile(params, lang);
    }
    const r = routes[path].path;
    if (typeof r === 'string' || r instanceof String)   {
        return r;
    }
    if(!r[lang])   {
        console.log("MISSING: ", `Ne postoji route lang: ${path} lang: ${lang}`)
        return r[0];
    }
    return r[lang];
}
export default useRoute;