import React, {useState} from "react";
import {types} from "../../config";
import {ChevronDown, Trash2} from "react-feather";
import {useMutation} from "@apollo/client";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import {DELETE_BLOCK, UPSERT_BLOCK} from "../../graphql/blocks";
import toast from "react-hot-toast";
import BlockBanners from "../designBlocks/BlockBanners";
import BlockCategory from "../designBlocks/BlockCategory";
import BlockProducts from "../designBlocks/BlockProducts";
import BlockCategories from "../designBlocks/BlockCategories";


const BlockItem = ({item, handler, refetch}) => {
    const [open, setOpen] = useState(false);
    const [deleteBlock] = useMutation(DELETE_BLOCK);

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrišete blok?"))  {
            return false;
        }
        deleteBlock({variables: {id: item.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div className="" style={{opacity: item.active ? 1 : 0.4}}>
            <div className="d-flex justify-content-between align-items-center">
                {handler}
                <div  className="flex-grow-1">
                    <div className="text-black-50 mb-1"> {types[item.type]?.name} </div>
                    <div className="row">
                        <div className="col-md-6">
                            <CmsUpdateField
                                element="text"
                                nullable={true}
                                placeholder="Unesite naziv bloka."
                                id={item.id} MUTATION={UPSERT_BLOCK}
                                name="title" value={item.title} />
                        </div>
                    </div>
                    <div className="d-flex">

                    </div>
                </div>
                <div>
                    <CmsUpdateField
                        element="checkbox"
                        id={item.id}
                        MUTATION={UPSERT_BLOCK}
                        name="active"
                        nullable={true}
                        value={item.active}
                        onTrue="Blok je aktiviran"
                        onFalse="Blok je deaktiviran"
                        className="mb-2" />
                    <button onClick={deleteItem} className="btn btn-light btn-sm w-100 mb-2">
                        <Trash2 size={16} />  Obrišite
                    </button> <br />
                    <button onClick={() => setOpen(!open)} className="btn btn-light btn-sm w-100">
                        <ChevronDown size={16} />  Podesavanja
                    </button>
                </div>
            </div>

            {open &&
            <div className="mt-4">
                {(item.type === 1) &&
                <BlockBanners block={item} refetch={refetch} />}
                {(item.type === 2) &&
                <BlockBanners block={item} refetch={refetch} />}
                {(item.type === 3) &&
                <BlockProducts block={item} refetch={refetch} />}
                {(item.type === 4) &&
                <BlockCategory block={item} refetch={refetch} />}
                {(item.type === 5) &&
                <BlockCategories block={item} refetch={refetch} />}
            </div>}
        </div>
    )
}

export default BlockItem;
