import React from "react";
import ProductsEmbla from "../components/ProductsEmbla";


const ProductsCarousel = ({products}) => {
    if(!products || products.length === 0)  {
        return null;
    }
    return (
        <ProductsEmbla products={products} />
    );
};

export default ProductsCarousel;