import Select from "react-select";
import {customSelectStyles} from "../../../../app/helpers";



function ShopOrderProducts({value,  url}) {
    const orderOptions = {
        "relevantnosti": {value: 1, label: "Po relevantnosti"},
        "nazivu": {value: 2, label: "Po nazivu"},
        "rastuce": {value: 3, label: "Po ceni rastuće"},
        "opadajuce": {value: 4, label: "Ceni opadajuće"}
    }

    return (
        <Select
            placeholder="Sortiraj"
            styles={customSelectStyles}
            value={Object.values(orderOptions).find(option => option.value === value)}
            onChange={(item) => url("order", item.value)}
            isSearchable={false}
            getOptionLabel={option => option.label}
            options={Object.values(orderOptions)}
        />
    )
}

export default ShopOrderProducts;