import React, {useContext, useState} from "react";
import {route, trans, widget} from "../../../modulesCompiler";
import BasketContext from "../../contexts/BasketContext";
import {ShoppingBag, X} from "react-feather";
import {checkNoRabat, formatPrice} from "../../../../app/helpers";
import ProductBagButton from "../../widgets/ProductBagButton";
import {useMutation, useQuery} from "@apollo/client";
import {PLACE_ORDER, PLACE_ORDER_MUTATION} from "../../graphql/order";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_SELF, GET_SELF_QUERY} from "../../../users/graphql/user";
import {useHistory} from "react-router-dom";


function Checkout() {
    const history = useHistory();
    const {basket, total, removeByBasket, empty} = useContext(BasketContext);
    const currency = trans("currency");
    const [error, setError] = useState(false);
    const [form, setForm] = useState({message: ""});
    const [placeOrder, placeResponse] = useMutation(PLACE_ORDER);
    const routeThankYou = route("orders.status");

    const {data} = useQuery(GET_SELF);
    const user = data ? data[GET_SELF_QUERY] : null;

    const updateForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm({...form, [name]: value});
    }
    const submit = (e) => {
        e.preventDefault();
        const bask = basket.map(item => {
            return {product_id: item.product_id, qty: item.qty}
        });
        setError(false);
        placeOrder({variables: {basket: JSON.stringify(bask), message: form.message}}).then(response => {
            const resp = response.data[PLACE_ORDER_MUTATION];
            if(resp.error)   {
                setError(resp.error);
                return false;
            }
            empty();
            history.push(routeThankYou + "/" + resp.code + "/thank-you");
        }).catch(error => {
            console.log(error);
        });
    }

    let fullDiscount = 0;
    let fullPdv = 0;
    let fullPrice = 0;

    return (
        <div className="container">
            {widget("public.Breadcrumbs", {links: [{trans: "Checkout"}]})}

            <div className="mb-6">
                {basket.length === 0
                    ? <div className="h5 text-center bg-white rounded-3 p-4">
                        <ShoppingBag size={34} />
                        <div className="mt-2">
                            Korpa je prazna.
                        </div>
                    </div>
                    : <div className="d-flex flex-column-reverse">
                        <div className="table-responsive">
                            <table className="table table-bordered bg-white rounded-3 mb-4 text-center">
                                <thead>
                                <tr className="fw-bold">
                                    <td style={{width: 50}}>Br.</td>
                                    <td style={{width: 140}}>Slika</td>
                                    <td style={{minWidth: 240}}>Artikal</td>
                                    <td>Šifra</td>
                                    <td style={{minWidth: 140}}>Cena</td>
                                    <td style={{width: 140}}>Kol.</td>
                                    {/*<td style={{minWidth: 140}}>RABAT</td>*/}
                                    <td style={{minWidth: 140}}>PDV</td>
                                    <td style={{minWidth: 140}}>UKUPNO</td>
                                </tr>
                                </thead>
                                <tbody>
                                {basket.map((item, index) => {
                                    const product = item.product;
                                    let price = product.process_price;
                                    let productDiscount = 0;
                                    let productPdv = 0;
                                    if(user?.discount && user.discount > 0 && !checkNoRabat(product.badges))  {
                                        productDiscount = (price/100) * user.discount;
                                        fullDiscount += (productDiscount * item.qty);
                                        price -= productDiscount;
                                    }
                                    if(product.pdv && product.pdv > 0)  {
                                        productPdv = (price/100) * product.pdv;
                                        fullPdv += (productPdv * item.qty);
                                        price += productPdv;
                                    }
                                    fullPrice += price * item.qty;
                                    return <tr key={item.id}>
                                        <td className="align-middle">{index + 1}.</td>
                                        <td className="align-middle">
                                            <div className="me-3" style={{width: 140}}>
                                                {/*<div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", product.image)})`}} />*/}
                                                <div className="bcg_image" style={{backgroundImage: `url(https://api.barok032.rs/images/${product.code}.jpg)`}} />
                                            </div>
                                        </td>
                                        <td className="fw-bold align-middle">
                                            {product.name}
                                            <div className="fw-normal">
                                                {product.minimalno > 0 && (
                                                    <>
                                                        <span className="fs-s"> Minimalna količina: </span> {product.minimalno} <br/>
                                                    </>
                                                )}
                                                {product.komadaUPaketu > 0  && (
                                                    <>
                                                        <span className="fs-s"> Količina u paketu: </span> {product.komadaUPaketu} <br/>
                                                    </>
                                                )}
                                                <span className="fs-s"> Jedinica mere: </span> {product.jedinicaMere} <br/>
                                                <span className="fs-s"> Stanje: </span> {product.qty < 100 ? product.qty : "100+"}
                                            </div>
                                        </td>
                                        <td className="align-middle">{product.code}</td>
                                        <td className="align-middle">
                                            {formatPrice(product.process_price)} {currency}
                                        </td>
                                        <td className="align-middle">
                                            <ProductBagButton item={product} />
                                        </td>
                                        {/*<td className="align-middle">*/}
                                        {/*    {checkNoRabat(product.badges) &&*/}
                                        {/*    <span className="fst-italic fs-s">*/}
                                        {/*        {checkNoRabat(product.badges).opis}*/}
                                        {/*    </span>}*/}
                                        {/*    <div>*/}
                                        {/*        {user?.discount || 0}%*/}
                                        {/*    </div>*/}
                                        {/*    {productDiscount > 0 &&*/}
                                        {/*    <div> {formatPrice(productDiscount)} {currency} </div>}*/}
                                        {/*</td>*/}
                                        <td className="align-middle">
                                            {product.pdv || 0}%
                                            {productPdv > 0 &&
                                            <div> {formatPrice(productPdv)} {currency} </div>}
                                        </td>
                                        <td className="fw-bold align-middle">
                                            {formatPrice(price * item.qty)} {currency}
                                            <div className="mt-2">
                                                <button onClick={() => removeByBasket(item.id)} className="btn btn-light shadow-sm">
                                                    <X /> Uklonite
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>

                        <form onSubmit={submit} action="/" className="mb-5">
                            <div className="row g-md-5 mb-3 align-content-stretch">
                                <div className="col-md-6 mb-3">
                                <textarea className="form-control h-100"
                                          name="message" value={form.message}
                                          onChange={updateForm}
                                          placeholder="Dodatna poruka:" />
                                </div>
                                <div className="col-md-6">
                                    <table className="table table table-bordered border-dark fw-bold mb-0 bg-white">
                                        <tbody>
                                        <tr>
                                            <td className="text-end p-3">Ukupno bez PDV: </td>
                                            <td className="p-3">{formatPrice(total())} {currency}</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td className="text-end p-3"> RABAT: </td>*/}
                                        {/*    <td className="p-3">*/}
                                        {/*        {user?.discount || 0}%*/}
                                        {/*        {fullDiscount > 0 &&*/}
                                        {/*        <span className="ms-2">({formatPrice(fullDiscount)}  {currency})</span>}*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td className="text-end p-3">Iznos PDV: </td>
                                            <td className="p-3">
                                                <span className="ms-2">{formatPrice(fullPdv)}  {currency}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-end p-3">UKUPNO: </td>
                                            <td className="text-danger p-3">
                                                {formatPrice(fullPrice)}  {currency}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="text-end">
                                <button type="submit" className="btn btn-secondary btn-lg">
                                    POŠALJI PORUDŽBINU
                                </button>
                            </div>
                            <GraphQLErrorMessage error={placeResponse.error} />
                            {error &&
                            <div className="alert alert-danger mt-3">{error}</div>}
                        </form>
                    </div>}
            </div>
        </div>
    )
}
export default Checkout;