import GlobalBasketDrawerWrapper from "./middlewares/GlobalBasketDrawerWrapper";
import HeaderBasketLink from "./widgets/HeaderBasketLink";
import BagBar from "./widgets/BagBar";
import GlobalBasketWrapper from "./middlewares/GlobalBasketWrapper";
import BasketDrawer from "./widgets/BasketDrawer";
import ProductBagButton from "./widgets/ProductBagButton";
import Checkout from "./views/checkout/Checkout";
import Orders from "./views/orders/Orders";
import UnseenOrderAppend from "./views/orders/UnseenOrdersAppend";
import OrderRender from "./widgets/OrderRender";
import OrderStatus from "./views/status/OrderStatus";

const orderRoot = "/porudzbina";
const routes = {
    checkout: {path: ["/checkout", "/checkout2", "/checkout3"], component: Checkout, middleware: "public.PublicMiddleware"},
    orders: {path: "/cms/porudzbine", component: Orders, middleware: "cms.AdminAuthenticatedMiddleware"},
    status: {path: `${orderRoot}/:code/:thankYou?`,
        compile: (code) => {
            if(!code)  return orderRoot;
            return `${orderRoot}/${code}`;
        },
        component: OrderStatus, middleware: "public.PublicMiddleware"},
};
const cmsLeftMenu = [
    {path: routes.orders.path, name: "Porudzbine", position: 5, append: UnseenOrderAppend},
];
const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    wrappers: [GlobalBasketWrapper, GlobalBasketDrawerWrapper],
    widgets:  {
        HeaderBasketLink: HeaderBasketLink,
        BasketDrawer: BasketDrawer,
        BagBar: BagBar,
        ProductBagButton: ProductBagButton,
        OrderRender: OrderRender,
    }
}
export default exports;