import apolloExports from "../modules/_apollo/exports";
import productsExports from "../modules/products/exports";
import googleExports from "../modules/google/exports";
import designExports from "../modules/design/exports";
import categoriesExports from "../modules/categories/exports";
import storageExports from "../modules/storage/exports";
import usersExports from "../modules/users/exports";
import ordersExports from "../modules/orders/exports";
import langExports from "../modules/_lang/exports";
import settingsExports from "../modules/settings/exports";
import seoExports from "./_seo/exports";
import publicExports from "../modules/_public/exports";
import cmsExports from "../modules/_cms/exports";
import routerExports from "../modules/_router/exports";
import React from "react";


const modules = {
    lang: langExports,      // 1. provera lang-a za apollo
    cms: cmsExports,
    seo: seoExports,
    public: publicExports,
    apollo: apolloExports,
    settings: settingsExports,
    google: googleExports,
    storage: storageExports,
    design: designExports,
    products: productsExports,
    categories: categoriesExports,
    orders: ordersExports,
    users: usersExports,
    router: routerExports,
}

export const routes = {};
export const widgets = {};
export const seo = {};
export let wrappers = [];
export const cmsLeftMenu = {100: []};


for(let key in modules) {
    const module = modules[key];
    if(module.routes)   {
        for(let rKey in module.routes) {
            const item = module.routes[rKey];
            routes[`${key}.${rKey}`] = item;
            if(item.seo)   {
                seo[`${key}.${item.seo.key}`] = item.seo;
            }
        }
    }
    if(module.widgets)   {
        for(let wKey in module.widgets) {
            widgets[`${key}.${wKey}`] = module.widgets[wKey];
        }
    }
    if(module.cmsLeftMenu)   {
        for(let i=0; i<module.cmsLeftMenu.length; i++) {
            const tab = module.cmsLeftMenu[i];
            const pos = tab.position || 100;
            if(!cmsLeftMenu[pos])  cmsLeftMenu[pos] = [];
            cmsLeftMenu[pos].push(tab);
        }
    }
    if(module.wrappers)   {
        wrappers = [...wrappers, ...module.wrappers];
    }
}

export const widget = (path, params=false) => {
    if(!widgets[path])       {
        console.log("MISSING: ", `Ne postoji widget: ${path}`)
        return null;
    }
    const Element = widgets[path];
    const arr = path.split(".");
    if(arr[1][0] === arr[1][0].toUpperCase())       {
        return <Element {...params} />
    }
    if(params !== false)  {
        return widgets[path](params);
    }
    return widgets[path];

}
export const widgetComponent = (path) => {
    if(!widgets[path])       {
        console.log("MISSING: ", `Ne postoji widget: ${path}`)
        return null;
    }
    return  widgets[path];
}

export const route = (path, params) => {
    return widget("lang.route", {path, params});
}
export const trans = (name) => {
    return widget("lang.trans", name);
}
