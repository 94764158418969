import {useQuery} from "@apollo/client";
import {GET_SELF, GET_SELF_QUERY} from "../graphql/admin";


function AdminGuard({children}) {
    const {data} = useQuery(GET_SELF);
    const admin = data ? data[GET_SELF_QUERY] : null;
    if(!admin) return null;
    return children;
}
export default AdminGuard;