import {useMutation} from "@apollo/client";
import React, {useState} from "react";
import {PlusCircle} from "react-feather";
import {UPSERT_BLOCK} from "../../graphql/blocks";
import {types} from "../../config";
import toast from "react-hot-toast";



const AddBlockForm = ({code, refetch}) => {
    const [open, setOpen] = useState(false);
    const [upsertBlock, {loading}] = useMutation(UPSERT_BLOCK);

    const submit = (type) => {
        upsertBlock({variables: {code: code, type: type}}).then(response => {
            toast.success("Sačuvano!");
            refetch();
            setOpen(false);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div className="mb-4">
            {!open
                ? <button onClick={() => setOpen(!open)} className="btn btn-success">
                <PlusCircle size={16} /> Dodajte blok
            </button>
                : <button onClick={() => setOpen(!open)} className="btn btn-light">
                    Zatvorite
                </button>}

            {open &&
            <div className="bg-white shadow mt-3 p-3 rounded border border-success border-3">
                {Object.values(types).map(item => {
                    return <button onClick={() => submit(item.type)} disabled={loading}
                        key={item.type} className="btn btn-light me-2">
                        <PlusCircle size={16} />  {item.name}
                    </button>
                })}
            </div>}
        </div>
    )
}

export default AddBlockForm;
