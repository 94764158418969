import SettingsContext from "../contexts/SettingsContext";
import {useQuery} from "@apollo/client";
import {GET_SETTINGS, GET_SETTINGS_QUERY} from "../graphql/setting";
import GraphQLErrorMessage from "../../../app/GraphQLErrorMessage";


function GlobalSettingsWrapper({children})    {
    const {error, data, refetch} = useQuery(GET_SETTINGS);

    if(error)   {
        return <GraphQLErrorMessage error={error} />
    }
    let settings = {};
    if(data)    {
        const dat = data[GET_SETTINGS_QUERY];
        for(let i=0; i<dat.length; i++)    {
            const item = dat[i];
            settings[item.field] = item;
        }
    }

    const getSetting = (field) => {
        if(!settings[field]) return {};
        return settings[field];
    }

    return <SettingsContext.Provider value={{getSetting, refetch}}>
        {children}
    </SettingsContext.Provider>
}
export default GlobalSettingsWrapper;