import React from "react";
import {useQuery} from "@apollo/client";
import {GET_PRODUCT, GET_PRODUCT_QUERY} from "../../graphql/product";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import ConnectedProducts from "./ConnectedProducts";

const Product = (props) => {
    const { data, loading, error, refetch } =  useQuery(GET_PRODUCT, {
        variables: {id: props.computedMatch?.params?.id}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const product = data[GET_PRODUCT_QUERY];

    return (
        <div className="container">
            <div className="row mb-3">
                <div className="col-md-3">
                    <div className="bg-white rounded-3 p-3 list_item">
                        <div className="bcg_image" style={{backgroundImage: `url(https://api.barok032.rs/images/${product.code}.jpg)`}} />
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="bg-white rounded-3 p-3 list_item">
                        <div className="mb-3">
                            <strong className="mb-2"> Naziv proizvoda: </strong>
                            <div>{product.name}</div>
                        </div>
                        <div className="mb-3">
                            <strong className="mb-2"> Sifra: </strong>
                            <div>{product.code}</div>
                        </div>
                        <div className="mb-3">
                            <strong className="mb-2"> Cena: </strong>
                            <div>{product.process_price}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded p-3">
                <h5 className="mb-3"> POVEZANI PROIZVODI </h5>
                <ConnectedProducts product={product} refetch={refetch} />
            </div>
        </div>
    )
}

export default Product;
