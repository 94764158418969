import LangContext from "../contexts/LangContext";
import {matchPath} from "react-router-dom";
import {routes} from "../../modulesCompiler";
import {useMemo, useState} from "react";
import {momentOverride, options} from "../config";
import moment from "moment";


function GlobalLangWrapper({children})    {

    let defaultLang = useMemo(() => {
        const pathname = decodeURI(window.location.pathname);
        let lang = 0;
        for(let key in routes) {
            const match = matchPath(pathname, {
                path: routes[key].path,
                exact: true,
                strict: false
            });
            if(match)   {
                const path = routes[key].path;
                if (typeof path === 'string' || path instanceof String)   {
                    lang = 0;
                    break;
                } else {
                    const index = path.indexOf(match.path);
                    if (index > -1) {
                        lang = index;
                        break;
                    }
                }
            }
        }
        return lang;
    }, []);
    if(parseInt(defaultLang) > (options.length - 1))  {
        defaultLang = 0;
    }
    const [lang, setLang] = useState(defaultLang);

    const trans =  {
        naslovna: ["Naslovna", "Насловна", "Home"],
        "o nama": ["O nama", "О нама", "About Us"],
        shop: ["Shop", "Продавница", "Shop"],
        blog: ["Blog", "Блог", "Blog"],
        kontakt: ["Kontakt", "Контакт", "Contact"],
        kategorije: ["Kategorije", "Категорије", "Categories"],
        "mreze": ["Mreže", "Мреже", "Networks"],
        "prodajna mesta": ["Prodajna mesta", "Продајна места", "Selling places"],
        "mapa sajta": ["Mapa sajta", "Мапа сајта", "Site map"],
        "kupovina": ["Kupovina", "Куповина", "Shopping"],
        "vazni linkovi": ["Važni linkovi", "Важни линкови", "Important links"],
        "currency": ["RSD", "РСД", "EUR"],
        "akcija": ["AKCIJA", "АКЦИЈА", "ACTION"],
        "novo": ["NOVO", "НОВО", "NEW"],
        "filter": ["Filter", "Филтер", "Filter"],
        "proizvoda": ["proizvoda", "производа", "products"],
        "prazna pretraga": ["Prazna pretraga", "Празна претрага", "Empty search"],
        "korpa je prazna": ["Korpa je prazna", "Корпа је празна", "Shopping bag is empty"],
        "ne postoji stranica": ["Ne postoji stranica.", "Не постоји страница.", "Page doesn't exists."],
    }

    if(momentOverride[lang])  {
        moment.updateLocale("rs", momentOverride[lang]);
    } else  {
        moment.updateLocale("rs", momentOverride[0]);
    }

    return <LangContext.Provider value={{lang: lang, setLang: setLang, trans: trans}}>
        {children}
    </LangContext.Provider>
}
export default GlobalLangWrapper;