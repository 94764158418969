import React, {Suspense} from "react";
import {route, widget} from "../../modulesCompiler";
import {Redirect} from "react-router-dom";
import {providerCheckUser} from "../userProvider";


const UserAuthenticatedMiddleware = ({ component: Component, ...rest }) => {
    const user = providerCheckUser();
    const loginRedirect = route("users.login");

    if(!user)  {
        return <Redirect to={loginRedirect} />
    }
    return <Suspense fallback={<div> </div>}>
        {widget("public.PublicLayout", {...{component: Component, ...rest}})}
    </Suspense>
};

export default UserAuthenticatedMiddleware;
