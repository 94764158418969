import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/StorageFragment";


export const BLOCK_BANNER_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    fragment BlockBannerFragment on BlockBanner {
        id
        active
        block_id 
        image_id
        mobile_image_id
        alt
        link
        mobile
        blank
        position
        image {
            ...StorageFragment
        }
        mobile_image    {
            ...StorageFragment
        }
    }
`;

export const UPSERT_BLOCK_BANNER = gql`
    ${BLOCK_BANNER_FRAGMENT} 
    mutation ($id: ID, $block_id: ID, $image_id: ID, $mobile_image_id: ID, $active: Boolean,
                $mobile: Boolean, $blank: Boolean, $alt: String, $link: String)  {
        upsertBlockBanner(id: $id, block_id: $block_id, image_id: $image_id, mobile_image_id: $mobile_image_id,
                    active: $active, mobile: $mobile, blank: $blank, alt: $alt, link: $link) {
            ...BlockBannerFragment
        }
    }
`
export const DELETE_BLOCK_BANNER = gql`
    ${BLOCK_BANNER_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlockBanner(id: $id) {
            ...BlockBannerFragment
        }
    }
`