import {useState} from "react";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {route} from "../../../modulesCompiler";
import {useMutation} from "@apollo/client";
import {LOGIN_USER, LOGIN_USER_QUERY} from "../../graphql/user";
import {providerLoginUser} from "../../userProvider";
import {Link} from "react-router-dom";
import {UserPlus} from "react-feather";


function Login()    {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRememeber] = useState(true);
    const [loginError, setLoginError] = useState("");
    const [loginMutation, {error, loading}] = useMutation(LOGIN_USER);
    const dashboardRedirect = route("users.dashboard");

    const submit = (e) => {
        e.preventDefault();
        setLoginError("");
        loginMutation({variables: {email: email, password: password}}).then(response => {
            const JWToken = response.data[LOGIN_USER_QUERY];
            if(JWToken.error)   {
                setLoginError(JWToken.error);
                return false;
            }
            if(JWToken.token)   {
                providerLoginUser(JWToken.token, remember);
                window.location.href = dashboardRedirect;
            }
        }).catch(({graphQLErrors}) => {
            console.log(graphQLErrors)
        });
    }

    return <div className="container mt-6">
        <div className="row justify-content-center">
            <div className="col-md-3">
                <form onSubmit={submit} className="p-3 pb-4 bg-white rounded-3">
                    <h4> Ulogujte se </h4>

                    <label className="d-block mb-3">
                        Email:
                        <input value={email} onChange={(e) => setEmail(e.target.value)}
                               type="email" className="form-control" required={true} />
                    </label>
                    <GraphQLErrorField error={error} field="email" />

                    <label className="d-block mb-3">
                        Password:
                        <input  value={password} onChange={(e) => setPassword(e.target.value)}
                                type="password" className="form-control" required={true} />
                    </label>
                    <label className="d-block mb-3">
                        <input onChange={(e) => setRememeber(!!e.target.checked)}
                               type="checkbox" checked={remember} /><span /> Zapamti me
                    </label>
                    <GraphQLErrorField error={error} field="password" />

                    <button disabled={loading} className="btn btn-secondary shadow w-100" type="submit">
                        Ulogujte se
                    </button>

                    <GraphQLErrorMessage error={error} />
                    {loginError &&
                    <div className="alert alert-danger mt-2">{loginError}</div>}

                    <Link to={route("users.register")} className="btn btn-white shadow w-100 mt-3">
                       <UserPlus /> Registrujte nalog
                    </Link>
                </form>
            </div>
        </div>
    </div>
}
export default Login;