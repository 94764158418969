import {gql} from "@apollo/client";
import {PRODUCT_FRAGMENT} from "./ProductFragment";
import {GRUPA_FRAGMENT} from "../../categories/graphql/categories";
import {PRODUCT_CONNECTED_FRAGMENT} from "./connected";


export const GET_PRODUCTS_QUERY = "getProducts";
export const GET_PRODUCTS = gql`
    ${PRODUCT_FRAGMENT} 
    query($first: Int, $page: Int, $search: String, $category: [ID], $order: Int, $price: Float, $novo: Boolean, $action: Boolean) {
        getProducts(first: $first, page: $page, search: $search, category: $category, order: $order, price: $price,
        novo: $novo, action: $action) {
            data {
                ...ProductFragment
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const GET_PRODUCT_QUERY = "getProduct";
export const GET_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    ${GRUPA_FRAGMENT}
    ${PRODUCT_CONNECTED_FRAGMENT}
    query($id: ID, $cms: Int) {
        getProduct(id: $id, cms: $cms) {
            ...ProductFragment
            category {
                ...GrupaFragment
            }
            connected   {
                ...ProductFragment
                connectedPivot   {
                    ...ProductConnectedFragment
                }
            }
        }
    }
`

export const UPSERT_PRODUCT_QUERY = "upsertProduct";
export const UPSERT_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID, $name: String, $code: String, $brand: String, $image_id: ID, $active: Boolean)  {
        upsertProduct(id: $id, name: $name, code: $code, brand: $brand, image_id: $image_id,
            active: $active) {
            ...ProductFragment
        }
    }
`
export const DELETE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID!) {
        deleteProduct(id: $id) {
            ...ProductFragment
        }
    }
`
export const PRODUCT_TO_TOP = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID!) {
        productToTop(id: $id) {
            ...ProductFragment
        }
    }
`