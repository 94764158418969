import {Search} from "react-feather";
import {useState} from "react";
import {route, trans, widget} from "../../modulesCompiler";
import {useQuery} from "@apollo/client";
import {GET_PRODUCTS, GET_PRODUCTS_QUERY} from "../graphql/product";
import {Link} from "react-router-dom";
import {checkNoRabat, formatPrice} from "../../../app/helpers";


function SearchModal({button}) {
    //const history = useHistory();
    //const shopRoute = useRoute("products.shop");
    const [search, setSearch] = useState("");

    const { data, previousData } = useQuery(GET_PRODUCTS, {
        variables: {first: 10, page: 1, search},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });
    let holder = data || previousData;
    const products = holder ? holder[GET_PRODUCTS_QUERY].data : [];

    const submit = (e) => {
        e.preventDefault();
        //history.push(shopRoute + "?search=" + search);
        //setSearch("");
    }

    return (
        <>
            {(products.length > 0 && search !== "") &&
            <div onClick={() => setSearch("")} className="overlay" />}

            <div className="flex-grow-1 position-relative" style={{maxWidth: 980, zIndex: 101}}>
                <form action="/" onSubmit={submit} className="">
                    <input  value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            type="text" placeholder="Pretraga..."
                            className="form-control border-0 form-control-lg bg-light ps-5" />
                    <Search strokeWidth="3" size={21} className="position-absolute text-black-50" style={{top: 14, left: 15}} />
                </form>

                {(products.length > 0 && search !== "") &&
                <div className="mt-2 bg-white rounded-3 position-absolute w-100 shadow-lg autocomplete text-start">

                    {products.map(item => {
                        return <div key={item.id}>
                            <Link onClick={() => setSearch("")} to={route("products.shopProduct", item)}>
                                <div className="d-flex align-items-center p-2">
                                    <div className="me-3" style={{width: 50}}>
                                        <div className="bcg_image" style={{backgroundImage: `url(https://api.barok032.rs/images/${item.code}.jpg)`}} />                                </div>
                                    <div>
                                        <h6 className="mb-0 d-flex align-items-center">
                                            {item.name}
                                            <div className="ms-3 align-items-center d-none d-md-flex">
                                                <div>
                                                    {item.meniId === 2 &&
                                                    <div className="bg-danger text-center fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2 mb-2">
                                                        AKCIJA
                                                    </div>}

                                                    {/*{checkNoRabat(item.badges) &&*/}
                                                    {/*<div title={checkNoRabat(item.badges).opis} className="bg-danger fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2">*/}
                                                    {/*    0 RABAT*/}
                                                    {/*</div>}*/}
                                                </div>

                                                {item.vreme > 0 &&
                                                <div className="bg-success fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2">
                                                    NOVO
                                                </div>}
                                            </div>
                                        </h6>
                                        {widget("users.UserGuard",
                                            {children: <div className="">
                                                    <span className="me-2">{item.process_price} {trans("currency")}</span>
                                                </div>})}

                                        <div className="text-black-50">#{item.code}</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    })}
                </div>}
            </div>
        </>
    )
}
export default SearchModal;