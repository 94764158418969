import Design from "./views/design/Design";
import BlocksRender from "./widgets/BlocksRender";


const routes = {
    design: {path: "/cms/dizajn", component: Design, middleware: "cms.AdminAuthenticatedMiddleware"},
};
const cmsLeftMenu = [
    {path: routes.design.path, name: "Dizajn", position: 4},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    widgets: {
        BlocksRender: BlocksRender
    }
}
export default exports;