import {parseJwt, setCookie} from "../../app/helpers";

const ADMIN_TOKEN = "adtokjwt";

export function providerLoginAdmin(token, remember = false) {
    var expires = null;
    if(remember)       {
        expires = 30;
    }
    setCookie(ADMIN_TOKEN, token, expires);
    return token;
}
export function providerCheckAdmin() {
    var name = ADMIN_TOKEN + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}
export function providerLogoutAdmin() {
    setCookie(ADMIN_TOKEN, '', -1);
}
export function providerGetAdminId() {
    const cookie = providerCheckAdmin();
    if(!cookie) return null;
    const parsed = parseJwt(cookie);
    if(!parsed) return null;
    return parsed.id;
}
