import Login from "./views/login/Login";
import Dashboard from "./views/dashboard/Dashboard";
import UserNotAuthenticatedMiddleware from "./middlewares/UserNotAuthenticatedMiddleware";
import UserAuthenticatedMiddleware from "./middlewares/UserAuthenticatedMiddleware";
import HeaderLoginLink from "./widgets/HeaderLoginLink";
import UserGuard from "./widgets/UserGuard";
import Users from "./views/users/Users";
import Register from "./views/register/Register";
import {providerCheckUser} from "./userProvider";
import Orders from "./views/orders/Orders";
import Order from "./views/order/Order";
import Discount from "./views/discount/Discount";

const routes = {
    users: {path: "/cms/korisnici", component: Users, middleware: "cms.AdminAuthenticatedMiddleware"},
    login: {path: ["/ulogujte-se", "/login"], component: Login, middleware: "users.UserNotAuthenticatedMiddleware"},
    register: {path: ["/registrujte-se"], component: Register, middleware: "users.UserNotAuthenticatedMiddleware"},
    dashboard: {path: ["/korisnicki-panel"], component: Dashboard, middleware: "users.UserAuthenticatedMiddleware"},
    discount: {path: ["/korisnik-rabat"], component: Discount, middleware: "users.UserAuthenticatedMiddleware"},
    orders: {path: ["/korisnik-porudzbine"], component: Orders, middleware: "users.UserAuthenticatedMiddleware"},
    order: {path: ["/korisnik-porudzbina/:code"],
        compile: (code) => {
            if (!code) return "/korisnik-porudzbine";
            return `/korisnik-porudzbina/${code}`;
        },
        component: Order, middleware: "users.UserAuthenticatedMiddleware"},
};
const cmsLeftMenu = [
    {path: routes.users.path, name: "Korisnici", position: 3},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    widgets: {
        UserNotAuthenticatedMiddleware: UserNotAuthenticatedMiddleware,
        UserAuthenticatedMiddleware: UserAuthenticatedMiddleware,
        HeaderLoginLink: HeaderLoginLink,
        UserGuard: UserGuard,
        providerCheckUser: providerCheckUser,

    }

}
export default exports;