import {useQuery} from "@apollo/client";
import {GET_ORDERS_UNSEEN, GET_ORDERS_UNSEEN_QUERY} from "../../graphql/order";


function UnseenOrderAppend() {
    const {data, error} = useQuery(GET_ORDERS_UNSEEN, {
        pollInterval: 5000,
        fetchPolicy: 'network-only'
    });
    if(!data) return null;
    const count = data[GET_ORDERS_UNSEEN_QUERY];
    if(!count) return  null;
    return (
        <div className="bg-danger rounded-circle fw-bold text-center" style={{width: 24, height: 24}}>
            {count}
        </div>
    )
}
export default UnseenOrderAppend;