import {Link} from "react-router-dom";
import React from "react";
import {widget} from "../../modulesCompiler";


function Banner({banner, className}) {
    if(!banner.active) return null;

    const image = <>
        <img
            className={`${className ? className : ""} d-none d-md-block img-fluid`}
            src={widget("storage.image")(banner.image, true)}
            alt={banner.alt}  />
        <img
            className={`${className ? className : ""} d-md-none img-fluid`}
            src={widget("storage.image")(banner.mobile_image, true)}
            alt={banner.alt} />
    </>

    if(banner.link)     {
        if(banner.blank)        {
            return <a href={banner.link} target="_blank" rel="noreferrer">
                <div className="img_overlay">
                {image}
                </div>
            </a>
        }
        return <Link to={banner.link}>
            <div className="img_overlay">
                {image}
            </div>
        </Link>
    }
    return image;
}
export default Banner;