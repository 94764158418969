import {Link} from "react-router-dom";
import {route, trans, widget} from "../../modulesCompiler";
import {checkNoRabat, formatPrice} from "../../../app/helpers";
import LinesEllipsis from 'react-lines-ellipsis'
import {Tooltip} from 'react-tooltip'
import {providerCheckUser} from "../../users/userProvider";
import React, { useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
function ProductRender({item}) {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenAvailable, setIsOpenAvailable] = useState(false)

    const user = providerCheckUser();
    const className = `bg-white p-3 rounded-3 position-relative  ${user ? 'item-height-logged' : ''}`;
    const descClassName = `text-black-50 desc-height  ${user ? 'desc-height' : 'desc-height-out'}`;

    const handleReflow = (rleState) => {
        const {
            clamped
        } = rleState
        if (clamped) {
            setIsOpenAvailable(true)
        }
    }
    const openTooltip = () => {
       if (isOpenAvailable) {
           setIsOpen(true)
       }
    }
    const closeToolTip = () => {
        setIsOpen(false)
    }
    const maxLines = isMobile ? 3: 2;
    const classHeight = isMobile ? 'title-height-mobile mb-2' : 'title-height mb-2'
    return (
        <div className={className}>
            <div className="d-flex justify-content-between align-items-baseline position-absolute"
                 style={{top: 8, left: 8, right: 8, zIndex: 10}}>
                <div>
                    {item.meniId === 2 &&
                    <div className="bg-danger text-center fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2 mb-2">
                        AKCIJA
                    </div>}

                    {/*{checkNoRabat(item.badges) &&*/}
                    {/*<div title={checkNoRabat(item.badges).opis} className="bg-danger fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2">*/}
                    {/*    0 RABAT*/}
                    {/*</div>}*/}
                </div>

                {item.new > 0 &&
                <div className="bg-success fw-bold fs-s text-white rounded-2 p-1 ps-2 pe-2">
                    NOVO
                </div>}
            </div>
            <Link to={route("products.shopProduct", item)}>
                <div className="text-center mb-3 img_overlay">
                    {/*<div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", item.image)})`}}/> TODO rmv */}
                    <div className="bcg_image" style={{backgroundImage: `url(https://api.barok032.rs/images/${item.code}.jpg)`}} />
                </div>
            </Link>
            <Link to={route("products.shopProduct", item)}>
                <Tooltip isOpen={isOpen} id={item.code} />
                <div className={classHeight}>
                    <LinesEllipsis
                        onMouseEnter={openTooltip}
                        onMouseLeave={closeToolTip}
                        data-tooltip-id={item.code}
                        data-tooltip-content={item.name}
                        className="fw-bold"
                        text={item.name}
                        maxLine={maxLines}
                        ellipsis='...'
                        trimRight
                        onReflow={handleReflow}
                        basedOn='letters'
                    />
                </div>

                <div className={descClassName}>
                    <span className="fs-s"> Šifra: </span> {item.code} <br/>
                    {item.minimalno > 0 && (
                        <>
                            <span className="fs-s"> Minimalna količina: </span> {item.minimalno} <br/>
                        </>
                    )}
                    {item.komadaUPaketu > 0 && (
                        <>
                            <span className="fs-s"> Količina u paketu: </span> {item.komadaUPaketu} <br/>
                        </>
                    )}
                    <span className="fs-s"> Jedinica mere: </span> {item.jedinicaMere} <br/>
                    {widget("users.UserGuard",
                        {
                            children: <>
                                <span className="fs-s"> Stanje: </span> {item.qty < 100 ? item.qty : "100+"}
                            </>
                        })}

                </div>
            </Link>

            {widget("users.UserGuard",
                {children: <div className="mt-3">
                    <div className="fw-bold text-secondary fs-5 mb-2">
                        {formatPrice(item.process_price)} {trans("currency")}
                    </div>

                    {widget("orders.ProductBagButton", {item})}
                </div>})}
        </div>

    )
}

export default ProductRender;