import React, {useState} from "react";
import {dateTime, formatPrice, fromNow} from "../../../../app/helpers";
import {trans} from "../../../modulesCompiler";
import {X} from "react-feather";
import Drawer from "rc-drawer";
import Order from "../Order/Order";


const OrderItem = ({item}) => {
    const [open, setOpen] = useState(false);
    const [openProducts, setOpenProducts] = useState(false);

    const close = () => {
        setOpen(false);
    }

    const toggleProducts = (e) => {
        e.preventDefault();
        setOpenProducts(!openProducts)
    }

    return (
        <>
            <Drawer
                open={open}
                onClose={close}
                width="90%"
                placement="right"
                level={null}
                handler={false}>
                <div className="pt-3 position-relative">
                    <button className="btn btn-danger position-absolute" style={{top: 15, right: 20}}
                            onClick={close}>
                        <X />
                    </button>

                    {open &&
                    <Order item={item} />}
                </div>
            </Drawer>

            <div className={`p-3 row pointer ${item.seen ? "" : "bg-unseen"}`}>
                <div onClick={() => setOpen(true)} className="col-md-4">
                    {item.status &&
                    <div className="alert alert-success pt-1 pb-1 mb-2 d-inline-block">
                        Porudžbina je obrađena
                    </div>}
                    <div className="mb-2">
                        {dateTime(item.created_at)}
                        <div className="text-black-50">{fromNow(item.created_at)}</div>
                    </div>
                    {item.code &&
                    <div className="mb-2">#{item.code}</div>}
                    <div className="fw-bold">Cena: {formatPrice(item.full_price)} {trans("currency")}</div>
                    {/*<div className="fw-bold">Rabat: {item.discount || 0}%</div>*/}
                </div>
                <div onClick={() => setOpen(true)} className="col-md-4">
                    <div>
                        {item.user.name}  {item.user.lastname}
                    </div>
                    <div>{item.user.email}</div>
                    <div className="mb-2">{item.user.phone}</div>
                    <div>{item.user.firm_name}</div>
                    {item.user.firm_address &&
                    <div>{item.user.firm_address}, {item.user.firm_place}</div>}
                </div>
            </div>
        </>
    )
}

export default OrderItem;
