import {useHistory} from "react-router-dom";
import {urlQueryOptions} from "./config";


function useGetSearchParams(keys) {
    const history = useHistory();

    const query = history.location.search; // could be '?foo=bar'
    const params =  new URLSearchParams(decodeURIComponent(query));

    const holder = [];
    for(let i=0; i<keys.length; i++) {
        const name = keys[i];
        if(!urlQueryOptions[name]) continue;
        let found = false;
        for(let j=0; j<urlQueryOptions[name].values.length; j++)    {
            let value = params.get(urlQueryOptions[name].values[j]);
            if(value) {
                if(urlQueryOptions[name].integer)    {
                    value = parseInt(value);
                }
                holder.push(value);
                found = true;
            }
        }
        if(!found)  {
            holder.push(urlQueryOptions[name].default);
        }
    }
    return holder;
}
export default useGetSearchParams;