import React, {useState} from "react";
import GeoStatistics from "./GeoStatistics";
import UsersStatistics from "./UsersStatistics";

const Analytics = (props) => {
    const [days, setDays] = useState(7);

    return (
        <div className="container-fluid">
            <div className="mb-4">
                <div className="btn-toolbar" role="toolbar">
                    <div className="btn-group shadow-sm mr-2" role="group">
                        {[
                            {name: "7 dana", value: 7},
                            {name: "30 dana", value: 30},
                            {name: "90 dana", value: 90}].map(item => {
                            return <button key={item.value} type="button" name="days" value={item.value}
                                           onClick={(e) => setDays(item.value)}
                                           className={`btn ${days === item.value ? "btn-primary" : "btn-light"}`}>
                                {item.name}
                            </button>
                        })}
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <GeoStatistics days={days} />
            </div>
            <UsersStatistics days={days} />
        </div>
    )
}

export default Analytics;
