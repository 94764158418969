import {useMutation} from "@apollo/client";
import {Lock, Settings, ShoppingBag, Trash2, UserPlus, X} from "react-feather";
import {ADMIN_UPDATE_USER, DELETE_USER} from "../../graphql/user";
import toast from "react-hot-toast";
import Drawer from "rc-drawer";
import React, {useState} from "react";
import CmsUpdateField from "../../../../app/CmsUpdateField";


const UserItem = ({item, refetch}) => {
    const [deleteUser] = useMutation(DELETE_USER);
    const [updateUser] = useMutation(ADMIN_UPDATE_USER);

    const [open, setOpen] = useState(false);
    const close = () => {
        setOpen(false);
    }

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrisete korisnika?"))  {
            return false;
        }
        deleteUser({variables: {id: item.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const verifyItem = () => {
        if(!window.confirm("Zelite da odobrite nalog?"))  {
            return false;
        }
        updateUser({variables: {id: item.id, verified: true}}).then(response => {
            toast.success("Odobreno!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const bannedItem = () => {
        updateUser({variables: {id: item.id, banned: !item.banned}}).then(response => {
            toast.success("Sacuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
console.log(item)
    return (
        <>
            <Drawer
                open={open}
                onClose={close}
                width="90%"
                placement="right"
                level={null}
                handler={false}>
                <div className="pt-3 position-relative">
                    <button className="btn btn-danger position-absolute" style={{top: 15, right: 20}}
                            onClick={close}>
                        <X />
                    </button>

                    {open &&
                    <div className="m-3 mb-5">
                        <div className="bg-white p-3 mb-3 rounded-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="fw-bold mb-1">Email</div>
                                    <div className="mb-3">{item.email}</div>

                                    <div className="fw-bold mb-1">Ime</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="name"
                                        required={true}
                                        placeholder="Ime"
                                        className="mb-3"
                                        value={item.name} />

                                    <div className="fw-bold mb-1">Prezime</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="lastname"
                                        required={true}
                                        placeholder="Prezime"
                                        className="mb-3"
                                        value={item.lastname} />

                                    <div className="fw-bold mb-1">Telefon</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="phone"
                                        required={true}
                                        placeholder="Telefon"
                                        className="mb-3"
                                        value={item.phone} />
                                </div>
                                <div className="col-md-6">
                                    <div className="fw-bold mb-1">Ime firme</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="firm_name"
                                        nullable={true}
                                        placeholder="Ime firme"
                                        className="mb-3"
                                        value={item.firm_name} />

                                    <div className="fw-bold mb-1">Adresa firme</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="firm_address"
                                        nullable={true}
                                        placeholder="Adresa firme"
                                        className="mb-3"
                                        value={item.firm_address} />

                                    <div className="fw-bold mb-1">Mesto firme</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="firm_place"
                                        nullable={true}
                                        placeholder="Mesto firme"
                                        className="mb-3"
                                        value={item.firm_place} />

                                    <div className="fw-bold mb-1">PIB firme</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="firm_pib"
                                        nullable={true}
                                        placeholder="PIB firme"
                                        className="mb-3"
                                        value={item.firm_pib} />

                                    <div className="fw-bold mb-1">Matični broj firme</div>
                                    <CmsUpdateField
                                        element="text"
                                        id={item.id}
                                        MUTATION={ADMIN_UPDATE_USER}
                                        name="firm_number"
                                        nullable={true}
                                        placeholder="Matični broj firme"
                                        className="mb-3"
                                        value={item.firm_number} />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white p-3 rounded-3">
                            <div className="row">
                                {/*<div className="col-md-6">*/}
                                {/*    <div className="h4">RABAT</div>*/}
                                {/*    <div style={{maxWidth: 360}}>*/}
                                {/*        <CmsUpdateField*/}
                                {/*            element="text"*/}
                                {/*            type="number"*/}
                                {/*            id={item.id}*/}
                                {/*            MUTATION={ADMIN_UPDATE_USER}*/}
                                {/*            name="discount"*/}
                                {/*            nullable={true}*/}
                                {/*            placeholder="0"*/}
                                {/*            className="mb-3"*/}
                                {/*            value={item.discount} />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-md-6">
                                    <div className="h4">KOMERCIJALISTI</div>
                                    <div style={{maxWidth: 360}}>
                                        <CmsUpdateField
                                            element="text"
                                            type="number"
                                            id={item.id}
                                            MUTATION={ADMIN_UPDATE_USER}
                                            name="commercial"
                                            nullable={true}
                                            placeholder="0"
                                            className="mb-3"
                                            value={item.commercial} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </Drawer>

            <div className="row align-items-center">
                {!item.verified &&
                <div className="col-md-12 fw-bold text-danger">
                    Nalog nije verifikovan.
                </div>}
                {item.banned &&
                <div className="col-md-12 fw-bold text-danger">
                    Nalog je banovan.
                </div>}
                <div className="col-md-4">
                    <div onClick={() => setOpen(true)} className="fw-bold pointer">{item.name} {item.lastname}</div>
                    {item.email} <br />
                    {item.phone}
                    {item.basket > 0 &&
                    <div className="mt-2">
                        <ShoppingBag /> <strong>{item.basket}</strong>  <span className="text-black-50"> (u korpi) </span>
                    </div>}
                    <div>
                        {item.basket_all.map(bask => {
                            return <div key={bask.id} className="mb-1 pb-1 border-bottom">
                                <span className="text-black-50">({bask.qty})</span> {bask.product.name}
                                <div className="text-black-50"> {bask.created_at} </div>
                            </div>
                        })}
                    </div>
                </div>
                {/*<div className="col-md-4">*/}
                {/*    <div className="mb-2"> Dodatak na cenu: {item.commercial || 0}% </div>*/}
                {/*    <div> Rabat: {item.discount || 0}% </div>*/}
                {/*</div>*/}
                <div className="col-md-4 d-flex justify-content-between">
                    <div>
                        {item.firm_name} <br />
                        {item.firm_address} <br />
                        {item.firm_place} <br />
                        {item.firm_pib} <br />
                        {item.firm_number} <br />
                    </div>
                    <div style={{width: 100}}>
                        {!item.verified &&
                        <button onClick={verifyItem} className="btn btn-success btn-sm w-100 mb-2">
                            <UserPlus size={16} /> Odobrite
                        </button>}
                        <button  onClick={() => setOpen(true)} className="btn btn-light btn-sm w-100 mb-2">
                            <Settings size={16} /> Detaljnije
                        </button>
                        <button onClick={deleteItem} className="btn btn-light btn-sm w-100 mb-2">
                            <Trash2 size={16} /> Obrišite
                        </button>
                        {item.banned
                            ?<button onClick={bannedItem} className="btn btn-danger btn-sm w-100">
                                Banovan
                            </button>
                            :<button onClick={bannedItem} className="btn btn-light btn-sm w-100">
                                <Lock size={16} /> Banujte
                            </button>}

                        <div className="mt-2 text-end">
                            greške: {item.count_login_attempts}
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default UserItem;
