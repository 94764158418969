import {route, trans, widget} from "../../../modulesCompiler";
import ShopProducts from "./ShopProducts";

function Action(props) {
    const shopBreadcrumbs = {trans: trans("shop"), route: route("products.shop")};

    return <div className="mb-6">
            {widget("public.Breadcrumbs", {links: [shopBreadcrumbs, {trans: 'Akcija'}]})}
            <div className="container mb-6">
                <ShopProducts count={5} action={true} />
            </div>
    </div>
}
export default Action;