import OrderRender from "../../widgets/OrderRender";
import {useMutation, useQuery} from "@apollo/client";
import {useEffect} from "react";
import {
    GET_ORDER_BY_CODE,
    GET_ORDER_BY_CODE_QUERY,
    UPDATE_ORDER
} from "../../graphql/order";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";


function Order({item}) {
    const [update] = useMutation(UPDATE_ORDER);
    const {data, loading, error} = useQuery(GET_ORDER_BY_CODE, {
        variables: {code: item.code},
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if(!item.seen) {
            update({variables: {id: item.id, seen: true}});
        }
    }, [item, update]);

    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const order = data[GET_ORDER_BY_CODE_QUERY];

    return  (
        <div className="m-3 mb-5">
            <h3 className="mb-4 text-center">Porudžbina #{item.code} </h3>

            <div className="mb-3">
                <CmsUpdateField
                    element="checkbox"
                    id={item.id}
                    MUTATION={UPDATE_ORDER}
                    name="status"
                    classes="btn-light"
                    nullable={true}
                    value={item.status}
                    onTrue="PORUDŽBINA JE OBRAĐENA"
                    onFalse="PORUDŽBINA JE NEOBRAĐENA" />
            </div>

            <OrderRender order={order} />
        </div>

    )
}
export default Order;