import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/StorageFragment";


export const KLASA_EXTEND_FRAGMENT = gql`        # TODO rmv
${STORAGE_FRAGMENT}
fragment KlasaExtendFragment on KlasaExtend {
    id
    image {
        ...StorageFragment
    }
    seo_title
    seo_keywords
    seo_description
}
`;
export const CATEGORY_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    ${KLASA_EXTEND_FRAGMENT}
    fragment CategoryFragment on Category {
        id
        name
        parent
        slug
        names
        slugs
        image   {
            ...StorageFragment
        }
        extend {
            ...KlasaExtendFragment
        }
        #count TODO rmv
    }
`;
export const GRUPA_EXTEND_FRAGMENT = gql`        # TODO rmv
${STORAGE_FRAGMENT}
fragment GrupaExtendFragment on GrupaExtend {
    id
    image {
        ...StorageFragment
    }
    seo_title
    seo_keywords
    seo_description
}
`;

export const GRUPA_FRAGMENT = gql`        # TODO rmv
    ${GRUPA_EXTEND_FRAGMENT}
    fragment GrupaFragment on Grupa {
        id
        name
        extend {
            ...GrupaExtendFragment
        }
    }
`;

export const GET_CATEGORIES_QUERY = "getCategories";
export const GET_CATEGORIES = gql`
    ${CATEGORY_FRAGMENT}
    ${GRUPA_FRAGMENT}
    query ($parent: Int) {
        getCategories(parent: $parent) {
            ...CategoryFragment
            children {
                ...GrupaFragment
            #    children {
            #        ...CategoryFragment
            #    }
            }
        }
    }
`

export const UPSERT_CATEGORY = gql` 
    ${CATEGORY_FRAGMENT}
    mutation ($id: ID, $name: String, $slug: String, $parent: ID, $image_id: ID)  {
        createCategory(id: $id, name: $name, slug: $slug, parent: $parent, image_id: $image_id) {
            ...CategoryFragment
        }
    }
`
export const UPSERT_GRUPA_EXTEND = gql`
    ${GRUPA_EXTEND_FRAGMENT}
    mutation ($id: ID, $grupa_id: ID, $image_id: ID, $seo_title: String, $seo_keywords: String, $seo_description: String)  {
        upsertGrupaExtend(id: $id, grupa_id: $grupa_id, image_id: $image_id,
            seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description) {
            ...GrupaExtendFragment
        }
    }
`
export const UPSERT_KLASA_EXTEND = gql`
    ${KLASA_EXTEND_FRAGMENT}
    mutation ($id: ID, $klasa_id: ID, $image_id: ID, $seo_title: String, $seo_keywords: String, $seo_description: String)  {
        upsertKlasaExtend(id: $id, klasa_id: $klasa_id, image_id: $image_id,
            seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description) {
            ...KlasaExtendFragment
        }
    }
`

export const DELETE_CATEGORY = gql`
    ${CATEGORY_FRAGMENT}
    mutation ($id: ID!) {
        deleteCategory(id: $id) {
            ...CategoryFragment
        }
    }
`
