import {useQuery} from "@apollo/client";
import {GET_SELF, GET_SELF_QUERY} from "../graphql/user";


function UserGuard({children}) {
    const {data} = useQuery(GET_SELF);
    const user = data ? data[GET_SELF_QUERY] : null;
    if(!user) return null;
    return children;
}
export default UserGuard;