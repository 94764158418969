import {gql} from "@apollo/client";


export const BLOCK_CATEGORIES_FRAGMENT = gql`
    fragment BlockCategoriesFragment on BlockCategories {
        id 
        block_id
        category_id
        position
    } 
`;

export const UPSERT_BLOCK_CATEGORIES = gql`
    ${BLOCK_CATEGORIES_FRAGMENT}
    mutation ($id: ID, $block_id: ID, $category_id: ID)  {
        upsertBlockCategories(id: $id, block_id: $block_id, category_id: $category_id) {
            ...BlockCategoriesFragment
        }
    }
`
export const DELETE_BLOCK_CATEGORIES = gql` 
    ${BLOCK_CATEGORIES_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlockCategories(id: $id) {
            ...BlockCategoriesFragment
        }
    }
`