import Storage from "../views/storage/Storage";
import {X} from "react-feather";
import Drawer from "rc-drawer";

function StorageDrawer({open, close, callback}) {

    return (
        <Drawer
            open={open}
            onClose={close}
            width="90%"
            placement="right"
            level={null}
            handler={false}>
            <div className="pt-3 position-relative">
                <button className="btn btn-danger position-absolute" style={{top: 15, right: 20}} onClick={close}>
                    <X />
                </button>

                {open &&
                <Storage callback={callback} close={close} />}
            </div>
        </Drawer>
    )
}
export default StorageDrawer;