import GraphQLErrorField from "../../../app/GraphQLErrorField";
import {useState} from "react";
import {useMutation} from "@apollo/client";
import {widget} from "../../modulesCompiler";
import toast from "react-hot-toast";
import StorageDrawer from "./StorageDrawer";


function UpdateImage({id, name, item, MUTATION, width, refetch, extend = {}}) {
    const [openStorage, setOpenStorage] = useState(false);
    const [update, {error}] = useMutation(MUTATION);

    const setImage = (images) => {
        if(!images) return false;
        if(images.length === 0) return false;
        const imageId = images[0].id;
        let form = {id: id, [name]: imageId};
        if(!id) {
            form = {...extend, [name]: imageId};
        }
        update({variables: form}).then(response => {
            toast.success("Sačuvano!");
            if(refetch) refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
console.log(id)
    const deleteItem = (e) => {
        e.preventDefault();
        if(!window.confirm("Zelite da uklonite sliku?"))  {
            return false;
        }
        update({variables: {id: id, [name]: null}}).then(response => {
            toast.success("Obrisano!");
            if(refetch) refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div>
            <StorageDrawer
                open={openStorage}
                close={() => setOpenStorage(false)}
                callback={setImage}
            />
            <div className="position-relative text-end">
                <div className="pointer text-center" onClick={() => setOpenStorage(true)}>
                    <img src={widget("storage.image", item || null)} alt="Slika" className="img-fluid" width={width} />
                    <div className="mt-2 fst-italic fs-s"> Kliknite na sliku da promenite</div>
                </div>
                {item &&
                <a href="/" onClick={deleteItem} className="fs-s fst-italic">
                    uklonite
                </a>}
            </div>


            <GraphQLErrorField error={error} field={name} />
        </div>
    )
}
export default UpdateImage;