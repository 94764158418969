import React from "react";
import PublicMiddleware from "./middlewares/PublicMiddleware";
import Error404 from "./widgets/Error404";
import Welcome from "./views/welcome/Welcome";
import About from "./views/about/About";
import Contact from "./views/contact/Contact";
import Breadcrumbs from "./widgets/Breadcrumbs";
import Pagination from "./widgets/Pagination";


const exports = {
    routes: {
        index: {path: ["/", "/насловна", "/home"], seo: {key: "index", title: "NASLOVNA strana"}, component: Welcome, middleware: "public.PublicMiddleware"},
        about: {path: ["/o-nama", "/о-нама", "/about-us"], seo: {key: "about", title: "O NAMA strana"}, component: About, middleware: "public.PublicMiddleware"},
        contact: {path: ["/kontakt", "/контакт", "/contact"], seo: {key: "contact", title: "KONTAKT strana"}, component: Contact, middleware: "public.PublicMiddleware"},
        error: {path: "*", component: Error404, middleware: "public.PublicMiddleware"},
    },
    widgets: {
        PublicMiddleware: PublicMiddleware,
        PublicLayout: React.lazy(() => import("./views/layout/PublicLayout")),
        Error404: Error404,
        Breadcrumbs: Breadcrumbs,
        Pagination: Pagination,
    }
}
export default exports;