import {route, trans, widget} from "../../../modulesCompiler";
import ShopProducts from "./ShopProducts";

function New(props) {
    const shopBreadcrumbs = {trans: trans("shop"), route: route("products.shop")};

    return <div className="mb-6">
            {widget("public.Breadcrumbs", {links: [shopBreadcrumbs, {trans: 'Novo'}]})}
            <div className="container mb-6">
                <ShopProducts count={5} novo={true} />
            </div>
    </div>
}
export default New;