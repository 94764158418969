import React from "react";
import {useQuery} from "@apollo/client";
import {GET_GEO_ANALYTICS, GET_GEO_ANALYTICS_QUERY} from "../../graphql/analytics";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import Chart from "react-google-charts";


const GeoStatistics = ({days}) => {
    const blockHeight = 300;
    const { data, loading, error } =  useQuery(GET_GEO_ANALYTICS, {
        variables: {days: days}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return <div className="alert alert-info"> Nema podataka. </div>
    const analytics = data[GET_GEO_ANALYTICS_QUERY];

    const geoChart = analytics.countries.map(item => [item.name, item.count]);
    const devices = analytics.devices.map(item => [item.name, item.count]);
    const browsers = analytics.browsers.map(item => [item.name, item.count]);
    let notSetCities = null;
    return (
        <div>
            <div className="row mb-4">
                <div className="col-md-8">
                    <div className="bg-white rounded-3 p-3 h-100">
                        <h3 className="mb-3">Zemlje sa najviše poseta</h3>
                        <div className="row">
                            <div className="col-md-4" style={{height: blockHeight, overflowY: "auto"}}>
                                {analytics.countries.map(item => {
                                    return <div key={item.name}>
                                        {item.name} : {item.count}
                                    </div>
                                })}
                            </div>
                            <div className="col-md-8">
                                <Chart
                                    width="100%"
                                    height={blockHeight}
                                    chartType="GeoChart"
                                    data={[
                                        ['Country', 'Popularity'],
                                        ...geoChart
                                    ]}
                                    mapsApiKey="AIzaSyCyGx2sbhw0bjmDoDR0Ra9ZtPHomWtz8W0"
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="bg-white rounded-3 p-3 h-100">
                        <h3 className="mb-3">Gradovi</h3>
                        <div  style={{height: blockHeight, overflowY: "auto"}}>
                            {analytics.cities.map(item => {
                                if(item.name === "(not set)")   {
                                    notSetCities = item.count;
                                    return null;
                                }
                                return <div key={item.name}>
                                    {item.name} : {item.count}
                                </div>
                            })}
                            {notSetCities &&
                            <div>
                                (not set) : {notSetCities}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="bg-white rounded-3 p-3 h-100">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="mb-3">Uredjaj</h3>

                                {analytics.devices.map(item => {
                                    return <div key={item.name}>
                                        {item.name} : {item.count}
                                    </div>
                                })}
                            </div>
                            <div className="col-md-8">
                                <Chart
                                    width="100%"
                                    height={200}
                                    chartType="PieChart"
                                    loader={<div>Loading...</div>}
                                    data={[
                                        ['Uredjaj', 'Broj'],
                                        ...devices
                                    ]}
                                    options={{is3D: true,}}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="bg-white rounded-3 p-3 h-100">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="mb-3">Browser</h3>
                                {analytics.browsers.map(item => {
                                    return <div key={item.name}>
                                        {item.name} : {item.count}
                                    </div>
                                })}
                            </div>
                            <div className="col-md-8">
                                <Chart
                                    width="100%"
                                    height={200}
                                    chartType="PieChart"
                                    loader={<div>Loading...</div>}
                                    data={[
                                        ['Browser', 'Broj'],
                                        ...browsers
                                    ]}
                                    options={{is3D: true,}}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GeoStatistics;
