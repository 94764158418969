import GlobalLangWrapper from "./middlewares/GlobalLangWrapper";
import UseTrans from "./widgets/useTrans";
import useRoute from "./widgets/useRoute";
import useGetLang from "./widgets/useGetLang";
import DropDown from "./widgets/DropDown";


const exports = {
    widgets: {
        trans: UseTrans,
        route: useRoute,
        useGetLang: useGetLang,
        DropDown: DropDown
    },
    wrappers: [GlobalLangWrapper]
}
export default exports;