

export function unmarshal(categories, acc=[], lvl=1)   {
    for(let i=0; i<categories.length; i++)      {
        const item = {...categories[i]};
        item.lvl = lvl;
        acc.push(item);
        if(item.children && item.children.length > 0)    {
            unmarshal(item.children, acc, lvl+1);
        }
    }
    return acc;
}

export function findBySlug(categories, slug) {
    const found =  breadcrumbsLoop(categories, slug, [], "slug");
    if(found) return found.reverse();
    return [];
}
export function findById(categories, slug) {
    const found =  breadcrumbsLoop(categories, slug, [], "id");
    if(found) return found.reverse();
    return [];
}
function breadcrumbsLoop(categories, slug, acc, what)       {
    for(let i=0; i<categories.length; i++) {
        const item = categories[i];
        if (item[what] === slug) {
            acc.push(item);
            return acc;
        }
        if(item.children)   {
            const pass = breadcrumbsLoop(item.children, slug, acc, what);
            if(pass)        {
                pass.push(item);
                return pass;
            }
        }
    }
    return false;
}