import React, {useContext, useEffect} from "react";
import BasketDrawerContext from "../contexts/BasketDrawerContext";
import BasketContext from "../contexts/BasketContext";



function HeaderBasketLink({button})  {
    const {setOpen} = useContext(BasketDrawerContext);
    const {basket, getBasket} = useContext(BasketContext);

    useEffect(() => {
        getBasket();
    }, [getBasket]);

    const openDrawer = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    return (
        <div className="icon_wrapper">
            <span>{basket.length}</span>
             {{...button, props: {...button.props, onClick: openDrawer}}}
        </div>
    )
}
export default HeaderBasketLink;