import React, {useState} from 'react';
import {Settings} from "react-feather";
import {widget} from "../../../modulesCompiler";
import {UPSERT_GRUPA_EXTEND, UPSERT_KLASA_EXTEND} from "../../graphql/categories";
import CmsUpdateField from "../../../../app/CmsUpdateField";


function CategoryItem({item, collapseIcon, refetch}) {
    const [settings, setOpen] = useState(false);
    const mutation = item.__typename === "Grupa" ? UPSERT_GRUPA_EXTEND : UPSERT_KLASA_EXTEND;
    const extendName = item.__typename === "Grupa" ? "grupa_id" : "klasa_id";

    return  <div>
        <div className="d-flex justify-content-between align-items-center">
            <div>
                {collapseIcon}
                {item.name}
            </div>
            <div>
                {item.image &&
                <img src={widget("storage.image", item.image)} alt={item.image.name} className="me-3" height={30} />}
                <button onClick={() => setOpen(!settings)} className="btn btn-light btn-sm">
                    <Settings size={16} />
                </button>
            </div>
        </div>
        {settings &&
        <div className="mt-2 border-top p-3">
            <div className="row">
                <div className="col-md-6">
                    <div style={{width: 220}}>
                        {widget("storage.UpdateImage", {
                            id: item.extend?.id,
                            MUTATION: mutation,
                            name: "image_id",
                            refetch: refetch,
                            extend: {[extendName]: item.id},
                            item: item.extend?.image})}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="h5">SEO TITLE</div>
                    <CmsUpdateField
                        element="textarea"
                        id={item.extend?.id}
                        MUTATION={mutation}
                        name="seo_title"
                        refetch={refetch}
                        nullable={true}
                        extend={{[extendName]: item.id}}
                        value={item.extend?.seo_title}
                        className="mb-3" />

                    <div className="h5">SEO KEYWORDS</div>
                    <CmsUpdateField
                        element="textarea"
                        id={item.extend?.id}
                        MUTATION={mutation}
                        name="seo_keywords"
                        refetch={refetch}
                        nullable={true}
                        extend={{[extendName]: item.id}}
                        value={item.extend?.seo_keywords}
                        className="mb-3" />

                    <div className="h5">SEO DESCRIPTION</div>
                    <CmsUpdateField
                        element="textarea"
                        id={item.extend?.id}
                        MUTATION={mutation}
                        name="seo_description"
                        refetch={refetch}
                        nullable={true}
                        extend={{[extendName]: item.id}}
                        value={item.extend?.seo_description}
                        className="mb-3" />
                </div>
            </div>
        </div>}
    </div>
}
export default CategoryItem;
