import React from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_USERS, GET_USERS_QUERY} from "../../graphql/user";
import {widget} from "../../../modulesCompiler";
import UserItem from "./UserItem";



const Users = (props) => {
    const { data, loading, error, refetch, variables, previousData } =  useQuery(GET_USERS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });
    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const users = holder ? holder[GET_USERS_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_USERS_QUERY].paginatorInfo : {};

    const changeForm = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if(value === "") value = undefined;
        refetch({[name]: value, page: 1});
    }

    return (
        <div className="container">
            <div className="mb-3 row">
                <div className="col-md-4">
                    <select name="basket" onChange={changeForm} className="form-control shadow-sm">
                        <option value="">svi korisnici</option>
                        <option value="1">sa punom korpom</option>
                    </select>
                    {/*<button className="btn btn-success">
                        <PlusCircle size={16} /> Dodajte korisnika
                    </button>*/}
                </div>
                <div className="col-md-4">
                    <input value={variables.search || ""} name="search" onChange={changeForm}
                           type="text" placeholder="Pretraga..." className="form-control shadow-sm" />
                </div>
                <div className="col-md-4 text-end">
                    {users.length > 0 &&
                    widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
                </div>
            </div>

            {users.length === 0 &&
            <div className="p-5 text-center"> Prazna pretraga </div>}
            {users.map(item => {
                return <div key={item.id} className="bg-white mb-2 shadow-sm p-3 list_item">
                    <UserItem item={item} refetch={refetch} />
                </div>
            })}

            <div className="mb-3 text-end">
                {users.length > 0 &&
                widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
            </div>
        </div>
    )
}

export default Users;
