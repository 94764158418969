import React from "react";
import {useMutation} from "@apollo/client";
import {widget} from "../../../modulesCompiler";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import toast from "react-hot-toast";
import {UPSERT_BLOCK_CATEGORY} from "../../graphql/category";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";



function BlockCategory({block, refetch}) {
    const [update, {error}] = useMutation(UPSERT_BLOCK_CATEGORY);

    const setCategory = (category) => {
        if(!category)    {
            toast.error("Kategorija ne sme biti prazna!");
            return false;
        }
        update({variables:
                {id: block.category?.id || undefined, block_id: block.id, category_id: category.id}
        }).then(response => {
            toast.success("Dodata kategorija!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return  (
        <div className="p-3">
            <GraphQLErrorMessage error={error} />
            <div className="text-black-50 mb-3">
                Karusel sa proizvodima iz odabrane kategorije.
            </div>
            <div className="mb-3">
                <div className="d-flex align-items-center mb-3">
                    <div className="me-2">Postavite kategoriju:</div>
                    {widget("categories.CategorySelect",
                        {value: block.category?.category_id, callback: setCategory})}
                </div>

                {block.category &&
                <>
                    <div className="d-flex align-items-center mb-3">
                        <div className="ms-2 me-2 text-nowrap">
                            Postavite prosečnu cenu:
                        </div>
                        <CmsUpdateField
                            element="text"
                            id={block.category?.id}
                            MUTATION={UPSERT_BLOCK_CATEGORY}
                            name="price"
                            nullable={true}
                            price={true}
                            value={block.category?.price} />
                        <div className="ms-2 text-black-50">
                            Ako je postavljena cena, prikazvace se proizvodi priblizne cene.
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="ms-2 me-2 text-nowrap">
                            Max broj proizvoda:
                        </div>
                        <CmsUpdateField
                            placeholder={20}
                            element="text"
                            type="number"
                            id={block.category?.id}
                            MUTATION={UPSERT_BLOCK_CATEGORY}
                            name="max"
                            nullable={true}
                            value={block.category?.max} />
                        <div className="ms-2 text-black-50">
                            Maksimalni broj proizvoda iz odabrane kategorije (default: 20).
                        </div>
                    </div>
                </>}

            </div>
        </div>
    )
}
export default BlockCategory;