import {Minus, Plus, ShoppingBag} from "react-feather";
import {useContext, useEffect, useState} from "react";
import BasketContext from "../contexts/BasketContext";


function ProductBagButton({item, full = true}) {
    const {basket, add, remove, update} = useContext(BasketContext);
    const find = basket.find(f => f.product.id === item.id);
    const [value, setValue] = useState(0);

    useEffect(() => {
        if(find?.qty === value) return;
        if(!find?.qty) {
            setValue(0);
            return;
        }
        setValue(parseInt(find?.qty));
    }, [find?.qty]);

    const addToBasket = () => {
        if (item.minimalno > 0) {
            add(item, item.minimalno);
        }else{
            add(item, 1);
        }
    }
    const onChange = (e) => {
        const value = e.target.value;
        if(value === "")    {
            setValue(0);
            return;
        }
        if(value > item.qty)  {
            alert(`Maksimalno na stanju: ${item.qty}`);
            return;
        }
        updateBasket(Number(value));
    }
    const onPlus = () => {
        let qty = 0
        if (item.minimalno > 0) {
            qty = value + item.minimalno;

        }else{
            qty = value + 1;
        }
        if(qty > item.qty)  {
            alert(`Maksimalno na stanju: ${item.qty}`);
            qty = item.qty;
        }
        updateBasket(qty);
    }
    const onMinus = () => {

        let qty =  value - 1;
        if (item.minimalno > 0) {
            qty = value - item.minimalno;
        }else{
            qty = value - 1;
        }
        if(qty < 1)   {
            setValue(0);
            return false;
        }
        updateBasket(qty);
    }
    const updateBasket = (qty) => {
        if(isNaN(qty))    {
            alert("Mora biti broj.");
            return false;
        }
        setValue(qty);
        //update(item.id, qty);
    }

    const submit = (e) => {
        e.preventDefault();
        if (item.minimalno > value && value !== 0) {
            alert(`Minimalna količina za korpu: ${item.minimalno}`);
            return
        }
        update(item.id, value);
    }

    const getInitValue = () => {
        if (value === 0) {
            return value
        }
        if (value > 0) {
            return value
        }
        if (item.minimalno > 0) {
            return item.minimalno
        }
        return 1
    }

    return (
        <>
            {!find
                ?  <button onClick={addToBasket}
                    className="btn btn-light">
                    {full && <span className="fw-bold me-1">DODAJTE U KORPU</span>} <ShoppingBag />
                </button>
                : <form onSubmit={submit} className="d-flex">
                    <div className="input-group product_qty_btn">

                        <div className="input-group-prepend">
                            <button onClick={onMinus} className="btn btn-dark btn_left shadow-none" type="button">
                                <Minus size={14} />
                            </button>
                        </div>
                        <input type="text" value={getInitValue()} onChange={onChange}
                               className="form-control bg-dark border-dark text-white fw-bold" placeholder=""  />
                        <div className="input-group-append">
                            <button onClick={onPlus} className="btn btn-dark btn_right shadow-none" type="button">
                                <Plus size={14} />
                            </button>
                        </div>
                    </div>
                    {find?.qty !== value &&
                    <button type="submit" className="btn btn-success ms-1">Sačuvajte</button>}
                </form>}
        </>

    )
}
export default ProductBagButton;