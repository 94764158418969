import React from "react";
import {ChevronLeft, ChevronRight} from "react-feather";

function Pagination({paginatorInfo, refetch}) {
    const {currentPage, lastPage, total} = paginatorInfo;

    return (
        <span className="d-inline-block">
            <span className="d-inline-block me-3">Ukupno: <strong> {total} </strong></span>

            {currentPage > 1 &&
            <button onClick={() => refetch({page: currentPage - 1})} className="btn btn-light shadow-sm me-1">
                <ChevronLeft />
            </button>}

            <select value={currentPage}
                    onChange={(e) => refetch({page: parseInt(e.target.value)})}
                    className="form-control shadow-sm d-inline-block" style={{width: 80}}>
                {[...Array(lastPage).keys()].map(item => {
                    item = item + 1;
                    return <option key={item} value={item}> {item} </option>
                })}
            </select>
            {currentPage < lastPage &&
            <button onClick={() => refetch({page: currentPage + 1})} className="btn btn-light shadow-sm ms-1">
               <ChevronRight />
            </button>}
        </span>
    )
}

export default Pagination;
