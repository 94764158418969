import {gql} from "@apollo/client";

export const GET_GEO_ANALYTICS_QUERY = "getGoogleGeoAnalytics";
export const GET_GEO_ANALYTICS = gql`
    query ($days: Int!) {
        getGoogleGeoAnalytics(days: $days) {
            countries {
                name
                count
            }
            cities {
                name
                count
            }
            devices {
                name
                count
            }
            browsers {
                name
                count
            }
        }
    }
`

export const GET_USERS_ANALYTICS_QUERY = "getGoogleUsersAnalytics";
export const GET_USERS_ANALYTICS = gql`
    query ($days: Int!) {
        getGoogleUsersAnalytics(days: $days)  {
            date
            activeUsers
            sessionsPerUser
            sessions
            screenPageViews
            newUsers
        }
    }
`
