import React from "react";
import {widget} from "../../../modulesCompiler";


function Welcome() {
    return <div className="mt-5 mb-5">

        {widget("design.BlocksRender", {code: "index"})}

    </div>
}
export default Welcome;