import {route} from "../../modulesCompiler";
import {Link} from "react-router-dom";
import {User} from "react-feather";
import {getUserName} from "../userProvider";
import {useQuery} from "@apollo/client";
import {GET_SELF, GET_SELF_QUERY} from "../graphql/user";


function HeaderLoginLink({button})  {
    const {data} = useQuery(GET_SELF);
    const user = data ? data[GET_SELF_QUERY] : null;

    return (
        button
            ?  button
            :   <Link to={route("users.login")} className="btn btn-link me-2 shadow-none">
                <User size={24} /> {user && <strong> {getUserName(user)} </strong>}
            </Link>

    )
}
export default HeaderLoginLink;