import {X} from "react-feather";
import Drawer from "rc-drawer";
import Products from "../views/products/Products";


function ProductsDrawer({open, close, callback}) {

    const callbackCalled = (resp) => {
        callback(resp);
        close();
    }

    return (
        <Drawer
            open={open}
            onClose={close}
            width="90%"
            placement="right"
            level={null}
            handler={false}>
            <div className="pt-3 position-relative">
                <button className="btn btn-danger position-absolute" style={{top: 15, right: 20}} onClick={close}>
                    <X />
                </button>

                {open &&
                <Products callback={callbackCalled} />}
            </div>
        </Drawer>
    )
}
export default ProductsDrawer;