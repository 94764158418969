import {dateTime} from "../../../../app/helpers";
import {useMutation} from "@apollo/client";
import {DELETE_ADMIN} from "../../graphql/admin";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {Trash2} from "react-feather";


const AdministratorItem = ({item, refetchAdmins}) => {

    const [deleteAdmin, deleteResponse] = useMutation(DELETE_ADMIN);

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrisete administratora?"))  {
            return false;
        }
        deleteAdmin({variables: {id: item.id}}).then(response => {
            refetchAdmins();
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="row align-items-center bg-white mb-2 shadow-sm p-3 list_item">
            <div className="col-md-6">
                {item.name} {item.lastname} <br />
                {item.email}
            </div>
            <div className="col-md-6 d-flex justify-content-between">
                <div>
                    Pogresni pokusaji: {item.count_login_attempts} <br />
                    Poslednji login: {dateTime(item.last_login)}
                </div>
                <div style={{width: 100}}>
                    <button onClick={deleteItem} className="btn btn-light btn-sm w-100">
                        <Trash2 size={16} /> Obrisite
                    </button>
                </div>
            </div>
            <GraphQLErrorMessage error={deleteResponse.error} />
        </div>
    )
}

export default AdministratorItem;
