import React from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_USER_ORDERS, GET_USER_ORDERS_QUERY} from "../../../orders/graphql/order";
import {route, trans, widget} from "../../../modulesCompiler";
import {dateTime, formatPrice, fromNow} from "../../../../app/helpers";
import {Link, useHistory} from "react-router-dom";
import Layout from "../layout/Layout";
import useGetSearchParams from "../../../../app/useGetSearchParams";


const Orders = (props) => {
    const currency = trans("currency");
    const history = useHistory();
    const [page] = useGetSearchParams(["page"]);

    const {data, loading, error, previousData} = useQuery(GET_USER_ORDERS, {
            variables: {first: 40, page},
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only"
        });

    if(loading && !previousData) {
        return <Layout><Loader /></Layout>
    }
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const orders = holder ? holder[GET_USER_ORDERS_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_USER_ORDERS_QUERY].paginatorInfo : {};

    const url = (field, value) => {
        if(field !== "page")    return;
        history.push("?page=" + value);
    }

    return (
        <Layout>
            {orders.length > 0 &&
            <div className="text-end mb-3">
                {widget("public.Pagination", {paginatorInfo, url})}
            </div>}

            <div className="bg-white rounded-3">
                {orders.length === 0
                    ? <div className="fw-bold p-4">Nemate porudžbina</div>
                    : <div>
                        {orders.map(item => {
                            return <div key={item.id} className="border-bottom mb-3 p-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            {dateTime(item.created_at)}
                                            <div className="text-black-50">{fromNow(item.created_at)}</div>
                                        </div>
                                        {item.code &&
                                        <div className="mb-2">#{item.code}</div>}
                                        <div className="fw-bold">Cena: {formatPrice(item.full_price)} {currency}</div>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <Link to={route("users.order", item.code)} className="btn btn-light shadow-sm">
                                            Detaljnije
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        })}
                    </div>}
            </div>

            <div className="mb-3 text-end">
                {orders.length > 0 &&
                widget("public.Pagination", {paginatorInfo, url})}
            </div>
        </Layout>
    )
}

export default Orders;
