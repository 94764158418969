import React from "react";
import {trans} from "../../modulesCompiler";


function Error404() {

    return (
        <div className="container text-center oswald d-flex align-items-center justify-content-center"
             style={{minHeight: 400}}>
            <div>
                <div className="h1 fw-bold">404</div>
                <div className="h4">{trans("ne postoji stranica")}</div>
            </div>
        </div>
    )
}
export default Error404;