import {gql} from "@apollo/client";


export const PRODUCT_CONNECTED_FRAGMENT = gql`
    fragment ProductConnectedFragment on ProductConnected {
        id
        product_id
        connected_id
        position
    }  
`;

export const UPSERT_PRODUCT_CONNECTED = gql`
    ${PRODUCT_CONNECTED_FRAGMENT}
    mutation ($id: ID, $connected_id: ID, $product_id: ID)  {
        upsertProductConnected(id: $id, connected_id: $connected_id, product_id: $product_id) {
            ...ProductConnectedFragment
        }
    }
`

export const DELETE_PRODUCT_CONNECTED = gql`
    ${PRODUCT_CONNECTED_FRAGMENT}
    mutation ($id: ID!) {
        deleteProductConnected(id: $id) {
            ...ProductConnectedFragment
        }
    }
`