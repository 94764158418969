import {gql} from "@apollo/client";


export const STORAGE_FRAGMENT = gql`
    fragment StorageFragment on Storage {
        id
        name
        orig_name
        mime
        width
        height
        alt
        bucket
        folder
    }
`;
