import {seo} from "../../../modulesCompiler";
import PageItem from "./PageItem";

function Pages()    {

    return (
        <div className="container">
            {Object.values(seo).map(item => {
                return  <PageItem key={item.key} item={item} />
            })}
        </div>
    )
}
export default Pages;