import {gql} from "@apollo/client";

export const SETTING_FRAGMENT = gql`
    fragment SettingFragment on Setting {
        id 
        field
        value
    }
`;
export const GET_SETTINGS_QUERY = "getSettings";
export const GET_SETTINGS = gql`
    ${SETTING_FRAGMENT}
    query {
        getSettings {
            ...SettingFragment
        }
    }
`
export const UPSERT_SETTING = gql`
    ${SETTING_FRAGMENT}
    mutation UpsertSetting($id: ID, $field: String, $value: String)  {
        upsertSetting(id: $id, field: $field, value: $value) {
            ...SettingFragment
        }
    }
`
