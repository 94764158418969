import {Link} from "react-router-dom";
import React, {useRef} from "react";
import {route, trans, widget} from "../../modulesCompiler";
import {checkNoRabat, dateTime, formatPrice} from "../../../app/helpers";
import {Printer} from "react-feather";
import ReactToPrint from "react-to-print";


class ComponentToPrint extends React.Component {
    render = () => {
        const order = this.props.order;
        const currency = this.props.currency;
        return (
            <div id="printable" className="d-none">
                <div className="ms-4 me-4 mt-4">
                    <h4 className="text-center fw-bold mb-2">Barok 032</h4>
                    <div className="row mb-3">
                        <div style={{width: "33%"}} className="col-md-4">
                            <div className="fw-bold border-bottom mb-1"> Osnovno </div>
                            <div className="mb-0">Šifra</div>
                            <div className="mb-1">
                                <strong>#{order.code}</strong>
                            </div>
                            <div className="mb-0">Cena</div>
                            <div className="mb-1">
                                <div><strong>{formatPrice(order.full_price)} {currency}</strong></div>
                            </div>
                            {/*<div className="mb-0">Rabat</div>*/}
                            {/*<div className="mb-1">*/}
                            {/*    <div><strong>{order.discount || 0}%</strong></div>*/}
                            {/*</div>*/}
                            <div className="mb-0">Datum</div>
                            <div className="mb-1">
                                <strong>{dateTime(order.created_at)}</strong>
                            </div>
                        </div>

                        <div style={{width: "33%"}} className="col-md-4">
                            <div className="fw-bold mb-1 border-bottom"> Kontakt </div>
                            <div className="mb-0">Ime i prezime</div>
                            <div className="mb-1">
                                <strong>{order.user.name} {order.user.lastname}</strong>
                            </div>

                            <div className="mb-0">Telefon</div>
                            <div className="mb-1">
                                <strong>{order.user.phone}</strong>
                            </div>
                            <div className="mb-0">Email</div>
                            <div className="mb-1">
                                <strong>{order.user.email}</strong>
                            </div>
                        </div>

                        <div style={{width: "33%"}} className="col-md-4">
                            <div className="fw-bold mb-1 border-bottom"> Firma </div>
                            <div className="mb-0">Ime</div>
                            <div className="mb-1">
                                <strong>{order.user.firm_name}</strong>
                            </div>
                            <div className="mb-0">Adresa</div>
                            <div className="mb-1">
                                <strong>{order.user.firm_address}</strong>
                            </div>
                            <div className="mb-0">Mesto</div>
                            <div className="mb-1">
                                <strong>{order.user.firm_place}</strong>
                            </div>
                            <div className="mb-0">PIB</div>
                            <div className="mb-1">
                                <strong>{order.user.firm_pib}</strong>
                            </div>
                            <div className="mb-0">Matični broj</div>
                            <div className="mb-1">
                                <strong>{order.user.firm_number}</strong>
                            </div>
                        </div>
                        {order.message &&
                            <div className="p-2">
                                <div className="fw-bold mb-1 border-bottom"> Dodatna napomena </div>
                                <div className="prewrap">{order.message}</div>
                            </div>}
                    </div>

                    <table className="table table-sm table-bordered bg-white"
                           style={{fontSize: 13}}>
                        <thead>
                        <tr className="fw-bold">
                            <td style={{width: 40}}>Br.</td>
                            {this.props.images && <td style={{width: 100}}>Slika</td>}
                            <td>Artikal</td>
                            <td style={{width: 80}}>Šifra</td>
                            {/*<td>Cena</td>*/}
                            <td style={{width: 50}}>Kol.</td>
                            {/*<td style={{width: 50}}>Rabat</td>*/}
                            <td style={{width: 50}}>PDV</td>
                            <td style={{width: 160}}>UKUPNO</td>
                        </tr>
                        </thead>
                        <tbody>
                        {order.products.map((product, index) => {
                            console.log(product)
                            return <tr key={product.orderPivot.id}>
                                <td className="align-middle pt-0 pb-0">{index + 1}.</td>
                                {this.props.images &&
                                    <td>
                                        <img className="img-fluid" style={{maxWidth: 100}}
                                             src={`https://api.barok032.rs/images/${product.code}.jpg`} alt={product.name} />
                                    </td>}
                                <td className="align-middle pt-0 pb-0">
                                    <div>
                                        <strong>{product.name}</strong>
                                        {/*<>
                                            <div>
                                                {formatPrice(product.orderPivot.orig_price)} {currency} +rab. +pdv = {formatPrice(product.orderPivot.price)} {currency}
                                            </div>
                                            {checkNoRabat(product.badges) &&
                                            <div className="fst-italic fw-normal">
                                                {checkNoRabat(product.badges).opis}
                                            </div>}
                                            </>*/}
                                    </div>
                                    {/*<div className="fw-normal">
                                            <span className="fs-s"> Količina u paketu: </span> {product.komadaUPaketu} <br/>
                                            <span className="fs-s"> Jedinica mere: </span> {product.jedinicaMere} <br/>
                                            <span className="fs-s"> Stanje: </span> {product.qty < 100 ? product.qty : "100+"}
                                        </div>*/}
                                </td>
                                <td className="align-middle pt-0 pb-0">{product.code}</td>
                                {/*<td className="align-middle">
                                        <div>
                                            Cena proizvoda: <br />
                                            <strong>{formatPrice(product.orderPivot.orig_price)} {currency}</strong>
                                        </div>
                                        Poručena cena: <br />
                                        <strong>{formatPrice(product.orderPivot.price)} {currency}</strong>
                                        {checkNoRabat(product.badges) &&
                                        <div className="fst-italic fs-s fw-normal">
                                            {checkNoRabat(product.badges).opis}
                                        </div>}
                                    </td>*/}
                                <td className="align-middle pt-0 pb-0">
                                    <strong>{product.orderPivot.qty}</strong>
                                </td>
                                {/*<td className="align-middle pt-0 pb-0">*/}
                                {/*    {checkNoRabat(product.badges)*/}
                                {/*        ? <span> 0% </span>*/}
                                {/*        : <span> {order.discount || 0}% </span>}*/}
                                {/*</td>*/}
                                <td className="align-middle pt-0 pb-0">
                                    {product.pdv}%
                                </td>
                                <td className="align-middle pt-0 pb-0">
                                    <strong>
                                        {formatPrice(product.orderPivot.price * product.orderPivot.qty)} {currency}
                                    </strong>
                                </td>
                            </tr>
                        })}
                        <tr className="fw-bold fs-5">
                            <td colSpan={7} className="p-2 pt-1 pb-1 text-end">
                                Ukupno:  {formatPrice(order.full_price)} {currency}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }
}

function OrderRender({order}) {
    const currency = trans("currency");
    const componentRef = useRef();
    const componentRef2 = useRef();

    let orderFullPrice = 0;

    return (
        <>
            <div className="mb-3">
                <ReactToPrint
                    trigger={() =><button className="btn btn-light me-2"><Printer /> Odštampajte</button>}
                    content={() => componentRef.current}
                    documentTitle={`Porudžbina #${order.code} - Barok 032`}
                />
                <ReactToPrint
                    trigger={() =><button className="btn btn-light"><Printer /> Odštampajte (sa slikama)</button>}
                    content={() => componentRef2.current}
                    documentTitle={`Porudžbina #${order.code} - Barok 032`}
                />
                <ComponentToPrint ref={componentRef} order={order} currency={currency} />
                <ComponentToPrint ref={componentRef2} order={order} currency={currency} images={true} />
            </div>
            <div>
                {/*<div className="mb-4 fs-4" style={{color: status.color}}>
                <strong> Status: {status.title} </strong>
            </div>*/}

                <div className="bg-white border mb-4 p-3">

                    <div className="row mb-3">
                        <div className="col-md-4">
                            <div className="fw-bold mb-3 border-bottom"> Osnovno </div>
                            <div className="mb-1">Šifra</div>
                            <div className="mb-3">
                                <strong>#{order.code}</strong>
                            </div>
                            <div className="mb-1">Cena</div>
                            <div className="mb-3">
                                <div><strong>{formatPrice(order.full_price)} {currency}</strong></div>
                            </div>
                            {/*<div className="mb-1">Rabat</div>*/}
                            {/*<div className="mb-3">*/}
                            {/*    <div><strong>{order.discount || 0}%</strong></div>*/}
                            {/*</div>*/}
                            <div className="mb-1">Datum</div>
                            <div className="mb-3">
                                <strong>{dateTime(order.created_at)}</strong>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="fw-bold mb-3 border-bottom"> Kontakt </div>
                            <div className="mb-1">Ime i prezime</div>
                            <div className="mb-3">
                                <strong>{order.user.name} {order.user.lastname}</strong>
                            </div>

                            <div className="mb-1">Telefon</div>
                            <div className="mb-3">
                                <strong>{order.user.phone}</strong>
                            </div>
                            <div className="mb-1">Email</div>
                            <div className="mb-3">
                                <strong>{order.user.email}</strong>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="fw-bold mb-3 border-bottom"> Firma </div>
                            <div className="mb-1">Ime</div>
                            <div className="mb-3">
                                <strong>{order.user.firm_name}</strong>
                            </div>
                            <div className="mb-1">Adresa</div>
                            <div className="mb-3">
                                <strong>{order.user.firm_address}</strong>
                            </div>
                            <div className="mb-1">Mesto</div>
                            <div className="mb-3">
                                <strong>{order.user.firm_place}</strong>
                            </div>
                            <div className="mb-1">PIB</div>
                            <div className="mb-3">
                                <strong>{order.user.firm_pib}</strong>
                            </div>
                            <div className="mb-1">Matični broj</div>
                            <div className="mb-3">
                                <strong>{order.user.firm_number}</strong>
                            </div>
                        </div>

                    </div>

                    {order.message &&
                        <div className="p-3 mb-3 bcg-2">
                            <div className="fw-bold mb-3 border-bottom"> Dodatna napomena </div>
                            <div className="prewrap">{order.message}</div>
                        </div>}
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered bg-white">
                        <thead>
                        <tr className="fw-bold">
                            <td style={{width: 50}}>Br.</td>
                            <td style={{minWidth: 140}}>Slika</td>
                            <td style={{minWidth: 240}}>Artikal</td>
                            <td>Šifra</td>
                            <td style={{minWidth: 320}}>Cena</td>
                            <td style={{width: 100}}>Kol.</td>
                            {/*<td style={{width: 80}}>Rabat</td>*/}
                            <td style={{width: 80}}>PDV</td>
                            <td>UKUPNO</td>
                        </tr>
                        </thead>
                        <tbody>
                        {order.products.map((product, index) => {
                            const productFullPrice = product.orderPivot.price * product.orderPivot.qty;
                            orderFullPrice += productFullPrice;
                            return <tr key={product.orderPivot.id}>
                                <td className="align-middle">{index + 1}.</td>
                                <td>
                                    <div className="bcg_image" style={{backgroundImage: `url(https://api.barok032.rs/images/${product.code}.jpg)`}} />
                                </td>
                                <td className="fw-bold align-middle">
                                    <Link to={route("products.shopProduct", product)}>
                                        <strong>{product.name}</strong>
                                    </Link>
                                    <div className="fw-normal">
                                        {product.minimalno > 0 && (
                                            <>
                                                <span className="fs-s"> Minimalna količina: </span> {product.minimalno} <br/>
                                            </>
                                        )}
                                        {product.komadaUPaketu > 0  && (
                                            <>
                                                <span className="fs-s"> Količina u paketu: </span> {product.komadaUPaketu} <br/>
                                            </>
                                        )}
                                        <span className="fs-s"> Jedinica mere: </span> {product.jedinicaMere} <br/>
                                        <span className="fs-s"> Stanje: </span> {product.qty < 100 ? product.qty : "100+"}
                                    </div>
                                </td>
                                <td className="align-middle"><b>{product.code}</b></td>

                                <td className="align-middle">
                                    <div>
                                        Cena proizvoda: <strong>{formatPrice(product.orderPivot.orig_price)} {currency}</strong>
                                    </div>
                                    Poručena cena: <strong>{formatPrice(product.orderPivot.price)} {currency}</strong>
                                    {/*<span className="text-black-50 ms-1">(+rabat +pdv)</span>*/}
                                    <span className="text-black-50 ms-1">(+pdv)</span>
                                    {/*{checkNoRabat(product.badges) &&*/}
                                    {/*<div className="fst-italic fs-s fw-normal">*/}
                                    {/*    {checkNoRabat(product.badges).opis}*/}
                                    {/*</div>}*/}
                                </td>
                                <td className="align-middle">
                                    <div className="mb-1">Količina:</div>
                                    <div className="mb-3">
                                        <strong>{product.orderPivot.qty}</strong>
                                    </div>
                                </td>
                                {/*<td className="align-middle">*/}
                                {/*    {checkNoRabat(product.badges)*/}
                                {/*        ? <span> 0% </span>*/}
                                {/*        : <span> {order.discount || 0}% </span>}*/}
                                {/*</td>*/}
                                <td className="align-middle">
                                    {product.pdv}%
                                </td>
                                <td className="align-middle">
                                    <div className="mb-1">Ukupno:</div>
                                    <div className="mb-3">
                                        <strong>
                                            {formatPrice(productFullPrice)} {currency}
                                        </strong>
                                    </div>
                                </td>
                            </tr>
                        })}
                        <tr className="fw-bold">
                            <td colSpan={7} className="p-3 fs-5 text-end"> Ukupno: </td>
                            <td width={180} className="p-3">
                                <div title="CENA PRILIKOM PORUCIVANJA" className="fs-5">
                                    {formatPrice(order.full_price)} {currency}
                                </div>
                                {widget("cms.AdminGuard", {
                                    children: <div title="IZRACUNATA CENA. AKO SE OVA CENA NE POKLAPA SA CENOM IZNAD, PRIJAVITI PROBLEM" className="fw-normal fst-italic">
                                        {formatPrice(orderFullPrice)} {currency}
                                    </div>
                                })}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    )
}
export default OrderRender;