import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../graphql/categories";
import GraphQLErrorMessage from "../../../app/GraphQLErrorMessage";
import React from "react";
import Select from "react-select";
import {unmarshal} from "../helpers";


function CategorySelect({value, callback}) {
    const { data, loading, error } =  useQuery(GET_CATEGORIES, {
        //variables: {parent: null} TODO rmv
    });
    if(loading) return null;
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return null;
    const categories = data[GET_CATEGORIES_QUERY];
    const unmarshalled = unmarshal(categories);

    return (
        <div style={{width: 260}}>
            <Select
                placeholder="Kategorije"
                value={unmarshalled.find(option => option.id === value) || ""}
                isClearable={true}
                isSearchable={true}
                onChange={callback}
                options={unmarshalled}
                getOptionLabel={option => " - ".repeat(option.lvl-1) + option.name}
                getOptionValue={option => option.id}
            />
        </div>
    )

}
export default CategorySelect;