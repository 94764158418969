import Analytics from "./views/analytics/Analytics";

const routes = {
    analytics: {path: "/cms/analytics", component: Analytics, middleware: 'cms.AdminAuthenticatedMiddleware'},
};
const cmsLeftMenu = [
    {path: routes.analytics.path, name: "Google Analitika", position: 1},
];
const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
}
export default exports;