import {useQuery} from "@apollo/client";
import {GET_PRODUCTS, GET_PRODUCTS_QUERY} from "../../graphql/product";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {trans, widget} from "../../../modulesCompiler";
import {useHistory} from "react-router-dom";
import {urlQueryOptions} from "../../../../app/config";
import useGetSearchParams from "../../../../app/useGetSearchParams";
import ShopOrderProducts from "./ShopOrderProducts";


function ShopProducts({category, count, novo, action}) {
    const history = useHistory();
    const emptySearch = trans("prazna pretraga");
    const transProducts = trans("proizvoda");
    const handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
            setTimeout(() => {
                sessionStorage.removeItem("scrollPosition");
                window.scrollTo(0, parseInt(scrollPosition));
            }, 100)
        }
    }
    const handleClick = e => {
        sessionStorage.setItem("scrollPosition", window.pageYOffset);
    }

    const searchParams = ["page", "order", "search"];
    const [page, order, search] = useGetSearchParams(searchParams);

    const {data, loading, error, previousData, variables} =  useQuery(GET_PRODUCTS, {
        variables: {first: 40, page, category: category, novo, action, order, search},
        fetchPolicy: 'network-only'
    });
    const productsData = data || previousData;
    if(loading && !productsData)    return <Loader />
    if(error)                       return <GraphQLErrorMessage error={error} />
    const products = productsData ? productsData[GET_PRODUCTS_QUERY].data : [];
    const paginatorInfo = productsData ? productsData[GET_PRODUCTS_QUERY].paginatorInfo : {};
    handleScrollPosition();
    const url = (field, value) => {
        let params = [];
        for(let i=0; i<searchParams.length; i++) {
            if(!urlQueryOptions[searchParams[i]]) continue;
            if(searchParams[i] !== field)   {
                if(urlQueryOptions[searchParams[i]].default === variables[searchParams[i]])  {
                    continue;
                }
                params.push(`${searchParams[i]}=${variables[searchParams[i]]}`);
            } else  {
                if(urlQueryOptions[searchParams[i]].default === value)  {
                    continue;
                }
                if(urlQueryOptions[searchParams[i]].array)  {
                    let values = variables[searchParams[i]];
                    if(values)   {
                        const valArr = values.split(",");
                        if(valArr.includes(value))  {
                            const mapped = valArr.filter(item => item !== value);
                            if(mapped.length === 0) {
                                continue;
                            }
                            values = mapped.join(",");
                            params.push(`${searchParams[i]}=${values}`);
                        } else  {
                            params.push(`${searchParams[i]}=${values + "," + value}`);
                        }
                    } else  {
                        params.push(`${searchParams[i]}=${value}`);
                    }
                } else  {
                    params.push(`${searchParams[i]}=${value}`);
                }
            }
        }
        let str = "?";
        if(params.length > 0)   {
            str += params.join("&");
        }
        history.push(str);
    }

    return (
        <div>
            {products.length === 0
                ? <div className="p-5 h4 text-center oswald fw-bold">
                    {emptySearch}
                </div>
                : <>
                    <div className="mb-3 d-md-flex align-items-end justify-content-between">
                        <div className="mb-2 mb-md-0">
                            {paginatorInfo.total} {transProducts}
                        </div>
                        <div className="d-md-flex">
                            <div className="me-2 mb-2 mb-md-0 position-relative" style={{width: 200, zIndex: 11}}>
                                <ShopOrderProducts value={variables.order} url={url} />
                            </div>

                            {widget("public.Pagination", {paginatorInfo, url})}
                        </div>
                    </div>

                    <div className={`row g-1 g-md-4 row-cols-md-${count} row-cols-2`}>
                        {products.map(item => {
                            return <div onClick={handleClick} key={item.id} className="mb-3">
                                {widget("products.ProductRender", {item: item})}
                            </div>
                        })}
                    </div>

                    <div className="mt-4 text-center">
                        {widget("public.Pagination", {paginatorInfo, url})}
                    </div>
                </>}
        </div>
    )
}

export default ShopProducts;