import {ShoppingBag} from "react-feather";
import {useContext, useEffect, useState} from "react";
import BasketDrawerContext from "../contexts/BasketDrawerContext";
import BasketContext from "../contexts/BasketContext";
import {formatPrice} from "../../../app/helpers";
import {trans} from "../../modulesCompiler";


function BagBar() {
    const {setOpen} = useContext(BasketDrawerContext);
    const {basket, total} = useContext(BasketContext);
    const [bottom, setBottom] = useState(false);
    const currency = trans("currency");

    const scrolled = () => {
        if ((window.innerHeight + window.pageYOffset + 10) >= document.body.offsetHeight) {
            setBottom(true);
        } else  {
            setBottom(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", scrolled);
    },[]);


    if(basket.length === 0) return null;

    return (
        <div className="bag_bar position-fixed w-100 fst-italic ps-2 pe-2 d-none d-md-block"
                style={{maxWidth: 860, bottom: bottom ? 60 : 30}}>

            <button onClick={() => setOpen(true)} className="btn btn-secondary shadow-lg d-md-flex justify-content-between align-items-center
                    pt-1 pb-1 pt-md-2 pb-md-2 ps-0 ps-md-5 pe-0 pe-md-5 w-100 fst-italic">
                <div>
                    <span className="icon_wrapper me-3">
                        <span className="white">{basket.length}</span>
                        <div className="m-1">
                        <ShoppingBag />
                        </div>
                    </span>

                    Dodate {basket.length} stvari
                </div>
                <div> {formatPrice(total())} {currency}</div>
            </button>
        </div>
    )
}
export default BagBar;